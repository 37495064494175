import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router'
import { format } from 'date-fns'
import clsx from 'clsx'

import { PortraitPage } from 'src/component'
import { utility } from 'src/common'
import { useFontLoader, useImageLoader } from 'src/hooks'

import { AcademyAwardT } from './AcademyAward.model'
import S from './AcademyAward.style'

const data2UIConverter = (data: AcademyAwardT): AcademyAwardT => {
  const ellipsisAcademyName =
    data.academyName.length > 18 ? `${data.academyName.slice(0, 18)}...` : data.academyName

  const date = format(new Date(data.date), 'yyyy년 M월 d일')

  return {
    ...data,
    academyName: ellipsisAcademyName,
    date,
  }
}

const AcademyAward = () => {
  const [isLoadComplete, setIsLoadComplete] = useState(false)
  const { areFontsLoaded } = useFontLoader()
  const { containerRef, areImagesLoaded } = useImageLoader()

  const loadedData = useLoaderData() as AcademyAwardT
  const uiData = data2UIConverter(loadedData)

  useEffect(() => {
    if (areFontsLoaded && areImagesLoaded) {
      setIsLoadComplete(true)
    }
  }, [areFontsLoaded, areImagesLoaded])

  return (
    <PortraitPage css={S.Container} ref={containerRef}>
      <div css={S.Result} id="result" className={clsx(isLoadComplete && 'complete')} />
      <img css={S.BackgroundRibbon} src={utility.s3URL('MAAT/award/BackgroundRibbon@3x.png')} />
      <img
        css={S.BackgroundMAATLogo}
        src={utility.s3URL('MAAT/award/MAATLogo@3x.png')}
        alt="MAAT Logo"
      />
      <img
        css={S.AbsoluteMedal}
        src={utility.s3URL(`MAAT/award/BEST_ACADEMY@3x.png`)}
        alt="Medal"
      />

      <div css={S.Wrapper}>
        <header>
          <label>{uiData.uuid}</label>
          최우수 기관상
        </header>
        <article css={S.MiddleArticle}>
          <p className="title">초등학교 {uiData.grade}학년 부문</p>
          <p className="academyName">{uiData.academyName}</p>
        </article>
        <article css={S.ContentArticle}>
          <p>
            위 기관은 한국수학교육학회에서 주최하는
            <br />제 {uiData.round}회 MAAT 수학경시대회에서
            <br />
            우수한 성적의 학생을 다수 배출하였기에
            <br />
            최우수교육기관으로 선정합니다.
          </p>
          <label css={S.SemiBoldFont}>{uiData.date}</label>
        </article>
        <footer css={S.Footer}>
          <div className="left">
            <p>
              <b css={S.ExtraBold}>주최</b>
              <span>한국수학교육학회</span>
            </p>
            <p>
              <b css={S.ExtraBold}>후원</b>
              <span>매쓰플랫</span>
            </p>
          </div>
          <div className="right">
            <img src={utility.s3URL('MAAT/award/KSMELogo@3x.png')} alt="KSME Logo" />
            <img src={utility.s3URL('MATHFLAT@3x.png')} alt="Mathflat Logo" />
          </div>
        </footer>
      </div>
    </PortraitPage>
  )
}

export default AcademyAward
