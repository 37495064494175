import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router'
import { PortraitPage } from 'src/component'

import { AbilityExamT } from '..'
import {
  Header,
  Info,
  나의_점수_chart,
  단원별_정답률_chart,
  단원별_진단,
  성적_변화_chart,
} from './component'
import S from './Report.style'

const translateTier2KR = (tier: AbilityExamT['Report']['type']) => {
  switch (tier) {
    case 'GRAND_PRIZE':
      return '대상'
    case 'GOLD_AWARD':
      return '금상'
    case 'SILVER_AWARD':
      return '은상'
    case 'BRONZE_AWARD':
      return '동상'
  }
}

const Report = () => {
  const data = useLoaderData() as AbilityExamT['Report']

  const [status, setStatus] = useState<'BEFORE_INITIALIZED' | 'READY'>('BEFORE_INITIALIZED')

  useEffect(() => {
    setTimeout(() => {
      setStatus('READY')
    }, 1000)
  }, [])

  // font prefetch - setTimeout(fn, ${N}ms)
  if (status === 'BEFORE_INITIALIZED') {
    return (
      <S.Ready>
        <div id="pretendard-400">pretendard-400</div>
        <div id="pretendard-700">pretendard-700</div>
        <div id="pretendard-800">pretendard-800</div>
      </S.Ready>
    )
  }

  const haveMorePage = data.correctRateByCurriculum.length !== 1
  const maxPage = haveMorePage ? 3 : 2

  return (
    <>
      {/* 
        #result 컴포넌트가 visibility: visible / display: true로 적용될 때 까지 pptr가 기다림 (waitForSelector) 
        이후 해당 컴포넌트의 classList에 completed가 있다면 성공 / failed가 있다면 실패 로직을 태움 (pptr 로직)
      */}
      <S.Result id="result" className={clsx('completed', !haveMorePage && 'no-need-more')} />
      <PortraitPage>
        <S.Wrapper className="break-inside-avoid">
          <S.Layout className="header">
            <Header data={data} />
          </S.Layout>
          <S.Layout className="info">
            <Info data={data} />
          </S.Layout>
          <S.Layout className="first">
            <S.Standard>
              <h2 className="title">1. 등급 기준</h2>
              <div className="table">
                <div className="row first-row">
                  <div className="tier">
                    <p>등급</p>
                  </div>
                  <div className="score">
                    <p>원점수</p>
                  </div>
                </div>
                {data.typeStandard.map(({ type: tier, maxScore, minScore }) => (
                  <div className="row" key={tier}>
                    <div className="tier">
                      <p>{translateTier2KR(tier)}</p>
                    </div>
                    <div className="score">
                      <p>
                        {minScore !== 0 && minScore}~{maxScore}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </S.Standard>
            <S.MyScore>
              <h2 className="title">2. 나의 점수</h2>
              <div>
                <나의_점수_chart data={data} />
              </div>
            </S.MyScore>
          </S.Layout>
          <S.Layout className="second">
            <h2 className="title">3. 성적 변화</h2>
            <div>
              <성적_변화_chart data={data} type="score" index={0} />
              <성적_변화_chart data={data} type="percentile" index={1} />
            </div>
          </S.Layout>
          <S.Layout className="third">
            <h2 className="title">4. 단원별 정답률</h2>
            <div className="wrap">
              <단원별_정답률_chart data={data} index={0} />
            </div>
          </S.Layout>
          <S.Layout className="footer">1/{maxPage}</S.Layout>
        </S.Wrapper>
      </PortraitPage>

      {/* 
        단원별 정답률 섹션이 1개 초과일 경우 2페이지가 만들어짐.
        이 경우, 무조건 1개 또는 2개의 케이스만 존재함.
      */}
      {haveMorePage && (
        <PortraitPage>
          <S.Wrapper>
            <S.Layout className="header">
              <Header data={data} />
            </S.Layout>
            <S.Layout className="third more-pages">
              <div className="wrap">
                <단원별_정답률_chart data={data} index={1} />
              </div>
            </S.Layout>
            <S.Layout className="footer">2/{maxPage}</S.Layout>
          </S.Wrapper>
        </PortraitPage>
      )}

      <PortraitPage>
        <S.Wrapper>
          <S.Layout className="header">
            <Header data={data} />
          </S.Layout>
          <단원별_진단 data={data} />
          <S.Layout className="footer">
            {maxPage}/{maxPage}
          </S.Layout>
        </S.Wrapper>
      </PortraitPage>
    </>
  )
}

export default Report
