import { css } from '@emotion/react'
import { MAATT } from '../..'
import { colors } from 'src/style'
import React from 'react'
import clsx from 'clsx'

type Props = {
  data: MAATT['ReportT']
}

const CategoryGraph = ({ data }: Props) => {
  return (
    <div css={S.Wrapper}>
      <section>
        <h6>내용 영역</h6>
        <div>
          <div css={S.GraphBackground}>
            {data.categoryUnit.map((_, idx) => (
              <div key={idx}>
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
              </div>
            ))}
            <div css={S.GraphXAxis}>
              <div css={S.MiniFont}>
                <p>0</p>
              </div>
              <div css={S.MiniFont}>
                <p>10</p>
              </div>
              <div css={S.MiniFont}>
                <p>20</p>
              </div>
              <div css={S.MiniFont}>
                <p>30</p>
              </div>
              <div css={S.MiniFont}>
                <p>40</p>
              </div>
              <div css={S.MiniFont}>
                <p>50</p>
              </div>
            </div>
          </div>

          <section css={S.GraphUI} style={{ height: '344px' }}>
            {data.categoryUnit.map((unit) => (
              <article key={unit.name}>
                <label css={S.MiniFont}>
                  {unit.name.length > 5 ? (
                    unit.name.split(' ').map((str) => <p key={str}>{str}</p>)
                  ) : (
                    <p>{unit.name}</p>
                  )}
                </label>
                <div css={S.GraphWrapper}>
                  <div
                    css={S.MinimumFont}
                    style={{
                      width: `${(unit.score / 50) * 100 * 1.95}px`,
                      background: colors.blue.$300,
                      color: '#fff',
                    }}
                  >
                    <p className={clsx(unit.score <= 5 && 'under-5', unit.score === 0 && 'zero')}>
                      {unit.score}
                    </p>
                  </div>
                  <div
                    css={S.MinimumFont}
                    style={{
                      width: `${(unit.totalScore / 50) * 100 * 1.95}px`,
                      background: colors.subYellow,
                      color: '#fff',
                    }}
                  >
                    <p
                      className={clsx(
                        unit.totalScore <= 5 && 'under-5',
                        unit.totalScore === 0 && 'zero',
                      )}
                    >
                      {unit.totalScore}
                    </p>
                  </div>
                  <div
                    css={S.MinimumFont}
                    style={{
                      width: `${(unit.averageScore / 50) * 100 * 1.95}px`,
                      background: colors.gray.$300,
                      color: colors.gray.$900,
                    }}
                  >
                    <p
                      className={clsx(
                        unit.averageScore <= 5 && 'under-5',
                        unit.averageScore === 0 && 'zero',
                      )}
                    >
                      {unit.averageScore}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </section>
        </div>
      </section>
      <section>
        <h6>행동 영역</h6>
        <div>
          <div css={S.GraphBackground}>
            {data.categoryBehavior.map((_, idx) => (
              <div key={idx}>
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
                <div css={S.GraphBackgroundBorder} />
              </div>
            ))}
            <div css={S.GraphXAxis}>
              <div css={S.MiniFont}>
                <p>0</p>
              </div>
              <div css={S.MiniFont}>
                <p>10</p>
              </div>
              <div css={S.MiniFont}>
                <p>20</p>
              </div>
              <div css={S.MiniFont}>
                <p>30</p>
              </div>
              <div css={S.MiniFont}>
                <p>40</p>
              </div>
              <div css={S.MiniFont}>
                <p>50</p>
              </div>
            </div>
          </div>

          <section css={S.GraphUI} style={{ height: '270px' }}>
            {data.categoryBehavior.map((unit) => (
              <article key={unit.name}>
                <label css={S.MiniFont}>
                  {unit.name.length > 5 ? (
                    unit.name.split(' ').map((str) => <p key={str}>{str}</p>)
                  ) : (
                    <p>{unit.name}</p>
                  )}
                </label>
                <div css={S.GraphWrapper}>
                  <div
                    css={S.MinimumFont}
                    style={{
                      width: `${(unit.score / 50) * 100 * 1.95}px`,
                      background: colors.blue.$300,
                      color: '#fff',
                    }}
                  >
                    <p className={clsx(unit.score <= 5 && 'under-5', unit.score === 0 && 'zero')}>
                      {unit.score}
                    </p>
                  </div>
                  <div
                    css={S.MinimumFont}
                    style={{
                      width: `${(unit.totalScore / 50) * 100 * 1.95}px`,
                      background: colors.subYellow,
                      color: '#fff',
                    }}
                  >
                    <p
                      className={clsx(
                        unit.totalScore <= 5 && 'under-5',
                        unit.totalScore === 0 && 'zero',
                      )}
                    >
                      {unit.totalScore}
                    </p>
                  </div>
                  <div
                    css={S.MinimumFont}
                    style={{
                      width: `${(unit.averageScore / 50) * 100 * 1.95}px`,
                      background: colors.gray.$300,
                      color: colors.gray.$900,
                    }}
                  >
                    <p
                      className={clsx(
                        unit.averageScore <= 5 && 'under-5',
                        unit.averageScore === 0 && 'zero',
                      )}
                    >
                      {unit.averageScore}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </section>
        </div>
      </section>

      <div css={S.GraphLabelWrapper}>
        <div css={S.MiniFont}>
          <div
            style={{
              width: '9px',
              height: '9px',
              borderRadius: '50%',
              background: colors.blue.$300,
            }}
          />
          <p>내 점수</p>
        </div>
        <div css={S.MiniFont}>
          <div
            style={{
              width: '9px',
              height: '9px',
              borderRadius: '50%',
              background: colors.subYellow,
            }}
          />
          <p>배점</p>
        </div>
        <div css={S.MiniFont}>
          <div
            style={{
              width: '9px',
              height: '9px',
              borderRadius: '50%',
              background: colors.gray.$300,
            }}
          />
          <p>전국 평균</p>
        </div>
      </div>
    </div>
  )
}

const S = {
  Wrapper: css`
    display: flex;
    justify-content: center;
    gap: 15px;

    > section {
      flex: 1;

      > h6 {
        margin-bottom: 20px;

        color: ${colors.gray.$800};
        font-size: 10px;
        font-weight: 700;
        line-height: normal;
      }

      > div {
        position: relative;
      }
    }
  `,

  GraphUI: css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: 30px 0px 26px 0px;

    > article {
      position: relative;
      display: flex;
      align-items: center;

      > label {
        width: 29px;
        > p {
          text-align: right;
        }
      }
    }
  `,

  GraphWrapper: css`
    position: absolute;
    left: 40px;

    display: flex;
    flex-direction: column;
    gap: 4px;

    > div {
      position: relative;
      display: flex;
      height: 14px;

      > p {
        margin: 3px 6px 4px auto;

        &.under-5 {
          position: absolute;
          margin: 4px 6px 4px auto;
          right: -16px;
          color: ${colors.gray.$900};
        }
        &.zero {
          right: -12px;
        }
      }
    }
  `,

  GraphBackground: css`
    position: absolute;
    top: 0px;
    left: 40px;
    z-index: -1;

    > div {
      display: flex;

      &:not(:first-of-type) > div {
        border-top: none;
      }
    }
  `,
  GraphBackgroundBorder: css`
    width: 39px;
    height: 68.8px;
    border: 1px dashed ${colors.gray.$300};

    &:not(:first-of-type) {
      border-left: none;
    }
  `,

  GraphXAxis: css`
    justify-content: space-between;

    width: 103%;
    margin: 4px 0px 0px -1px;
  `,

  MiniFont: css`
    color: ${colors.gray.$900};

    > p {
      font-size: 10px;
      line-height: 10px;
      letter-spacing: -0.2px;
      zoom: 0.8;
    }
  `,
  MinimumFont: css`
    > p {
      font-size: 10px;
      font-weight: 800;
      line-height: normal;
      zoom: 0.6;

      display: flex;
      align-items: center;
    }
  `,

  GraphLabelWrapper: css`
    position: absolute;
    display: flex;
    bottom: 51px;
    left: 40px;
    gap: 8px;

    > div {
      display: flex;
      gap: 8px;
    }
  `,
}

export default CategoryGraph
