import { academyAwardModel, AcademyAwardPage, AcademyAwardT } from './AcademyAward'
import { awardModel, AwardPage, AwardT } from './Award'
import { reportModel, ReportPage, ReportT } from './Report'

const MAAT = {
  awardModel,
  academyAwardModel,
  reportModel,
  AwardPage,
  AcademyAwardPage,
  ReportPage,
}

export { MAAT }
export type MAATT = { AwardT: AwardT; ReportT: ReportT; AcademyAwardT: AcademyAwardT }
