import styled from '@emotion/styled'
import { colors, textEllipsis } from 'src/style'

const S = {
  Ready: styled.div`
    opacity: 0;

    > #pretendard-400 {
      font-family: Pretendard !important;
      font-weight: 400;
    }
    > #pretendard-700 {
      font-family: Pretendard !important;
      font-weight: 700;
    }
    > #pretendard-800 {
      font-family: Pretendard !important;
      font-weight: 800;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 34px 40px 34px 40px;
  `,
  Layout: styled.div`
    .title {
      color: ${colors.gray.$900};
      font-size: 14px;
      font-weight: bold;
      line-height: 16.8px;
    }

    &.header {
      margin: 0 0 16px 0;
    }

    &.footer {
      display: flex;
      justify-content: center;
      font-size: 9px;
      line-height: 10.21px;

      position: absolute;
      bottom: 33px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.info {
      margin: 0 0 34px 0;
    }

    &.first {
      display: flex;
      gap: 12px;
    }

    &.second {
      margin: 0 0 36px 0;

      > div {
        display: flex;
        gap: 19px;
      }
    }

    &.third {
      position: relative;

      &.more-pages {
        > .wrap {
          margin-top: 0;
          padding-top: 12px;
        }
        .chart-title {
          top: 0 !important;
        }
      }

      > .wrap {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }
    }
  `,
  Standard: styled.div`
    .table {
      display: flex;
      gap: 2px;
      overflow: hidden;
      margin: 29px 0 0 0;
      width: fit-content;
      border-radius: 6px;
      font-size: 8px;
      line-height: 9.6px;
    }
    .row {
      display: flex;
      flex-direction: column;
      gap: 2px;
      &.first-row {
        div {
          width: 36px;
          color: #fff;
          font-weight: 700;
          background: ${colors.gray.$600};
        }
      }
    }
    .tier,
    .score {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      color: #000;
      background: ${colors.gray.$200};

      p {
        font-size: 10px;
        transform: scale(0.8);
      }
    }
    .tier {
      height: 24px;
    }
    .score {
      height: 36px;
    }
  `,
  MyScore: styled.div`
    > div {
      margin-top: 15px;
    }
  `,
  Judgment: styled.div`
    .title {
      margin: 0 0 21px 0;
    }
    .chapter-wrapper {
      display: flex;
      gap: 15px;
      margin: 0 0 23px 0;
    }

    .problem-table {
      .header {
        display: flex;
        height: 12px;
        margin: 0 0 6px 0;

        .answer > p {
          text-align: left;
        }

        > div {
          > p {
            zoom: 0.8;
            color: #000;
            font-size: 10px;
            line-height: 12px;
            text-align: center;

            margin: 0;
          }
        }
      }

      .row {
        display: flex;
        height: 22px;
        padding: 5px 0;
        border-bottom: 0.5px solid ${colors.gray.$300};

        &:last-of-type {
          border-bottom: none;
        }

        .is-correct {
          display: flex;
          align-items: center;
          justify-content: center;

          > img {
            width: 10px;
            height: 10px;
          }
        }
        .chapter {
          text-align: start;
        }

        > div,
        p {
          color: #000;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
        }
      }

      .index {
        width: 30px;
        margin: 0 12px 0 0;
        height: 12px;
        &.good:not(.score-100) {
          border: 1px solid #00abff;
        }
        &.bad:not(.score-0) {
          border: 1px solid #f19537;
        }
        &:not(.good):not(.bad) {
          margin-top: 1px;
        }

        > p {
          zoom: 0.8;
        }
      }
      .answer {
        width: 68px;
        margin: 0 20px 0 0;

        > p {
          text-align: left;
        }

        &.single-choice > p {
          margin-top: 2px;
          zoom: 0.8;
        }
      }
      .is-correct {
        width: 30px;
        margin: 0 20px 0 0;
      }
      .chapter {
        width: 237px;
        margin: 0 19px 0 0;
      }
      .score {
        width: 17px;
        margin: 0 25px 0 0;
      }
      .correct-rate {
        width: 40px;
      }
    }
  `,
  BestWorstInfo: styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 80px;

    .sub-title {
      display: flex;
      margin: 0 0 6px 0;
      width: 100%;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;

      > .best {
        margin: 0 208px 0 0;
        color: ${colors.blue.$300};
      }
      > .worst {
        color: #f19537;
      }
    }

    .info-table {
      display: flex;
      gap: 15px;

      .col {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;

        .row {
          flex: 1;
          padding: 2px 10px;
          background: ${colors.gray.$200};

          > p {
            zoom: 0.8;
            color: #000;
            font-size: 10px;
            line-height: 14.4px;
            ${textEllipsis(1)};
          }
        }
      }
    }

    &.highest,
    &.lowest {
      p {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        zoom: 0.8;
        color: #000;
        font-size: 10px;
        line-height: 14.4px;
        background: ${colors.gray.$200};
      }
    }
  `,
  Result: styled.div`
    display: none;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -10;

    &.completed {
      &.나의_점수.성적_변화0.성적_변화1.단원별_정답률0 {
        &.no-need-more {
          display: block;
          visibility: visible;
        }

        &.단원별_정답률1 {
          display: block;
          visibility: visible;
        }
      }
  `,
}

export default S
