import { AbilityExam, MAAT } from 'src/domain'
import { ZodAny } from 'zod'

import routeName, { RoutePathUnion } from '../routes/routeName'

class Validator {
  private urlModelRecord = {
    [routeName.학력평가_인증서]: AbilityExam.certificationModel,
    [routeName.학력평가_결과지]: AbilityExam.reportModel,
    [routeName.학력평가_상장]: AbilityExam.awardModel,
    [routeName.MAAT_상장]: MAAT.awardModel,
    [routeName.MAAT_결과지]: MAAT.reportModel,
    [routeName.MAAT_기관상장]: MAAT.academyAwardModel,
  }

  private getModelByURL = (url: string): ZodAny => {
    return this.urlModelRecord[new URL(url).pathname as RoutePathUnion]
  }

  checkContentByURL(content, url: string) {
    const validationModel = this.getModelByURL(url)

    if (!validationModel) {
      throw new Error('해당하는 경로에 맞는 모델이 존재하지 않습니다.')
    }

    return validationModel.parse(content)
  }
}

const validator = new Validator()

export default validator
