import styled from '@emotion/styled'
import { colors, typo } from 'src/style'

const S = {
  Result: styled.div`
    display: none;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -10;

    &.completed {
      display: block;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    > header {
      display: flex;
      justify-content: space-between;
      padding: 2px 0 78px 0;
      ${typo.caption01};
      color: #000;
    }

    > main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  `,
  HeaderRight: styled.div`
    display: flex;
    align-items: flex-start;

    .freewheelin-logo {
      width: 90px;
      margin-right: 17px;
    }
    .mathflat-logo {
      width: 60px;
    }
  `,
  Title: styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    padding: 0 0 255px 0;

    > span {
      display: flex;
      font-family: 'Spoqa Han Sans Neo' !important;

      ${typo.heading05};

      > .divider {
        width: 1px;
        height: 12px;
        margin: 6px;
        background: ${colors.gray.$900};
      }
    }

    > h1 {
      font-weight: bold;
      font-family: 'Spoqa Han Sans Neo' !important;
      ${typo.heading01};
    }
  `,
  Info: styled.div`
    display: flex;
    position: absolute;
    gap: 6px;
    flex-direction: column;
    padding: 0 0 0 45px;
    bottom: 387px;

    .category {
      display: flex;
      align-items: center;
      flex: 0 0 70px;
      font-weight: bold;
    }

    .student-name {
      width: 100%;
      ${typo.heading04};
    }

    > .row {
      display: flex;
      width: 237px;
      ${typo.caption01};
    }
  `,
  Medal: styled.img`
    top: 294px;
    left: 374px;
    position: absolute;
  `,
  Content: styled.div`
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;

    .content {
      text-align: center;
      width: 494px;
      font-size: 22px;
      font-weight: 700;
      line-height: 46px;
    }

    .done-date {
      display: flex;
      align-items: center;
      justify-content: center;
      ${typo.body02};
      padding: 0 0 46px 0;
    }
  `,
  Footer: styled.footer`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    > p {
      ${typo.body02};
    }

    > .mathflat-signature {
      margin-left: 10px;
      width: 50px;
    }
  `,
}

export default S
