import * as Sentry from '@sentry/react'
import { browserTracingIntegration } from '@sentry/browser'

const monitoringModule = {
  initialize: () => {
    if (import.meta.env.VITE_VERCEL_ENV === 'live') {
      Sentry.init({
        dsn: 'https://1c30bcab9e024d1d846d7ec00e7f0f9e@o1194468.ingest.sentry.io/4504641941078016',
        integrations: [browserTracingIntegration()],
        environment: import.meta.env.VITE_VERCEL_ENV ?? 'development',
        tracesSampleRate: 0.2,
      })
    }
  },
  sendMessage: (message: string) => {
    Sentry.captureMessage(message)
  },
  sendError: (exception: any) => {
    Sentry.captureException(exception, { level: 'error' })
  },
}

export default monitoringModule
