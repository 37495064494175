import styled from '@emotion/styled'
import { colors } from 'src/style'

import { AbilityExamT } from '../..'

type Props = {
  data: AbilityExamT['Report']
}

const Info = ({ data }: Props) => {
  return (
    <S.Info>
      <div className="title-wrapper">
        <h1 className="title">RESULT</h1>
        <span className="sub-title">진단결과</span>
      </div>
      <div className="info">
        <div className="default-info">
          <div className="academy">
            <b>학원명</b>
            <div className="divider" />
            <p>{data.academyName}</p>
          </div>
          <div className="student">
            <b>학생이름</b>
            <div className="divider" />
            <p>{data.name}</p>
          </div>
        </div>
        <S.ExamInfo>
          <div className="score">
            <p>점수</p>
            <span>{data.score}</span>
          </div>
          <div className="divider" />
          <div className="percentile">
            <p>백분위</p>
            <span>{data.percentile}%</span>
          </div>
          {data.type && (
            <>
              <div className="divider" />
              <div className="tier">
                <p>등급</p>
                <img
                  src={`${
                    import.meta.env.VITE_AWS_IMAGE_URL
                  }/ability-exam/report/${encodeURIComponent(data.type)}@3x.png`}
                  width={100}
                />
              </div>
            </>
          )}
        </S.ExamInfo>
      </div>
    </S.Info>
  )
}

const S = {
  Info: styled.div`
    .title-wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 8px 0;

      .title {
        margin: 0 6px 0 0;
        color: #1eacdd;
        font-weight: 700;
        font-size: 28px;
        line-height: 34.13px;
        font-family: 'Montserrat';
      }
      .sub-title {
        color: #1eacdd;
        font-size: 12px;
        line-height: 14.4px;
      }
    }

    .info {
      width: 515px;

      .default-info {
        display: flex;
        font-size: 10px;
        line-height: 12px;
        border: 1px solid ${colors.gray.$400};
        border-radius: 6px 6px 0 0;
        background: ${colors.gray.$300};

        b {
          font-weight: bold;
        }
        .divider {
          margin: 0 20px;
          width: 1px;
          height: 8px;
          background: ${colors.gray.$900};
        }

        .academy,
        .student {
          display: flex;
          align-items: center;
          flex: 1;
          padding: 7px 0 7px 20px;
        }
        .student {
          border-left: 1px solid ${colors.gray.$400};
        }
      }
    }
  `,
  ExamInfo: styled.div`
    display: flex;
    border: 1px solid ${colors.gray.$400};
    border-top: none;
    border-radius: 0 0 6px 6px;

    .divider {
      width: 1px;
      height: 62px;
      background: ${colors.gray.$400};
    }

    .score,
    .percentile,
    .tier {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 13px 0;

      > p {
        font-size: 10px;
        line-height: 12px;
      }
    }
    .score,
    .percentile {
      display: flex;
      gap: 10px;
      color: ${colors.gray.$800};
      font-weight: 700;

      span {
        font-size: 30px;
        line-height: 36.01px;
      }
    }
    .score {
      color: ${colors.gray.$800};
    }
    .percentile {
      color: ${colors.blue.$300};
    }
    .tier {
      position: relative;
      color: ${colors.gray.$800};

      > p {
        position: absolute;
        left: 32px;
        font-weight: 700;
      }
      > img {
        position: absolute;
        top: 0px;
        right: 10px;
      }
    }
  `,
}

export default Info
