import styled from '@emotion/styled'

import Page from './Page'

const PortraitPage = styled(Page)`
  width: 595px;
  height: 842px;
  /* width: 21cm;
  height: 29.7cm; */

  @media print {
    @page {
      size: portrait !important;
    }
  }
`

export default PortraitPage
