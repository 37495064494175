import z from 'zod'

const reportModel = z.object({
  type: z
    .enum(['GRAND_PRIZE', 'GOLD_AWARD', 'SILVER_AWARD', 'BRONZE_AWARD', 'PARTICIPATION_AWARD'])
    .nullable(),
  uuid: z.string().regex(/\d-\d/),
  studentName: z.string().min(1),
  academyName: z.string().min(1),
  examTitle: z.string().min(1),
  examRound: z.number().gte(1),
  examGrade: z.number().gte(1).lte(6),
  examSchoolType: z.enum(['ELEMENTARY', 'MIDDLE', 'HIGH']),
  score: z.number().gte(0).lte(100),
  rate: z.number().gte(0).lte(100),
  repBigChapterName: z.array(z.string().min(1)),
  weakBigChapterName: z.array(z.string().min(1)),
  categoryBehavior: z.array(
    z.object({
      name: z.string().min(1),
      totalScore: z.number().min(0).max(50),
      averageScore: z.number().min(0).max(50),
      score: z.number().min(0).max(50),
    }),
  ),
  categoryUnit: z.array(
    z.object({
      name: z.string().min(1),
      totalScore: z.number().min(0).max(50),
      averageScore: z.number().min(0).max(50),
      score: z.number().min(0).max(50),
    }),
  ),
  problemSummary: z.array(
    z.object({
      number: z.number().min(1),
      chapter: z.string().min(1),
      categoryCognitionName: z.string().min(1),
      score: z.number().min(0).max(100),
      result: z.enum(['CORRECT', 'WRONG']),
      average: z.number().min(0).max(100),
    }),
  ),
  roadMap: z.string().min(1),
})

type ReportT = z.infer<typeof reportModel>

export { reportModel }
export type { ReportT }
