import { css } from '@emotion/react'

import { colors } from 'src/style'

import { MAATT } from '../..'

type Props = {
  data: MAATT['ReportT']
}

const Header = ({ data }: Props) => {
  return (
    <header css={S.Header}>
      <span>{data.examTitle}</span>
      <div className="line" />
    </header>
  )
}

const S = {
  Header: css`
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0 0 16px 0;

    width: 100%;

    > span {
      flex-shrink: 0;
      font-size: 10px;
      line-height: 12px;
      color: ${colors.gray.$900};
    }

    > .line {
      width: 100%;
      height: 1px;
      background: ${colors.gray.$500};
    }
  `,
}

export default Header
