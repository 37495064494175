import { AbilityExamT, MAATT } from 'src/domain'

type dataT = {
  abilityExam: {
    certification_superior: AbilityExamT['Certification']
    certification_normal: AbilityExamT['Certification']
    report: AbilityExamT['Report']
    award: AbilityExamT['Award']
  }
  MAAT: {
    award: MAATT['AwardT']
    academyAward: MAATT['AcademyAwardT']
    report: MAATT['ReportT']
    report_0점: MAATT['ReportT']
  }
}
const CONSTANT: dataT = {
  abilityExam: {
    certification_superior: {
      name: '김용현',
      type: 'superior',
      score: 100,
      tier: 'S',
      yearMonth: '2022-12',
      year: 2022,
      month: 12,
      academyName: '매쓰플랫 수학학원',
      examTitle: '중1 제1회 매쓰플랫 학력평가 (2023년 2월)',
      uuid: '202302000122-0234567',
    },

    certification_normal: {
      name: '김용현',
      type: 'normal',
      score: 75,
      tier: 'B+',
      yearMonth: '2023-01',
      year: 2023,
      month: 1,
      academyName: '매쓰플랫 수학학원',
      examTitle: '중1 제1회 매쓰플랫 학력평가 (2023년 1월)',
      uuid: '202302000122-0234567',
    },

    report: {
      name: '김용현',
      score: 100,
      type: 'GRAND_PRIZE',
      typeStandard: [
        {
          type: 'GRAND_PRIZE',
          maxScore: 100,
          minScore: 95,
        },
        {
          type: 'GOLD_AWARD',
          maxScore: 94,
          minScore: 85,
        },
        {
          type: 'SILVER_AWARD',
          maxScore: 84,
          minScore: 75,
        },
        {
          type: 'BRONZE_AWARD',
          maxScore: 74,
          minScore: 65,
        },
      ],
      percentile: 99,
      academyName: '매쓰플랫',
      examTitle: '[중1] 제1회 매쓰플랫 전국 중등 학력평가 (2023년 2월)',
      national: {
        average: 0,
        averageOfTop30th: 30.4,
      },
      trendOfScoreAndPercentile: [
        {
          yearMonth: '2022-12',
          year: 2022,
          month: 12,
          score: 100,
          schoolTypeAndGrade: '중1',
          percentile: 0,
        },
        {
          yearMonth: '2022-12',
          year: 2022,
          month: 12,
          score: 90,
          schoolTypeAndGrade: '중1',
          percentile: 0,
        },
        {
          yearMonth: '2022-12',
          year: 2022,
          month: 12,
          score: 70,
          schoolTypeAndGrade: '중1',
          percentile: 0,
        },
        {
          yearMonth: '2022-12',
          year: 2022,
          month: 12,
          score: 90,
          schoolTypeAndGrade: '중1',
          percentile: 0,
        },
        {
          yearMonth: '2022-12',
          year: 2022,
          month: 12,
          score: 85,
          schoolTypeAndGrade: '중1',
          percentile: 0,
        },
        {
          yearMonth: '2022-12',
          year: 2022,
          month: 12,
          score: 0,
          schoolTypeAndGrade: '중1',
          percentile: 0,
        },

        // {
        //   yearMonth: '2023-01',
        //   year: 2023,
        //   month: 1,
        //   score: 0,
        //   percentile: 0,
        // },
        // {
        //   yearMonth: '2023-02',
        //   year: 2023,
        //   month: 2,
        //   score: 0,
        //   percentile: 0,
        // },
      ],
      correctRateByCurriculum: [
        {
          curriculumTitle: '중3-1',
          correctRate: 80,
          wrongRate: 20,
          data: [
            {
              curriculumTitle: '제곱근과 실수',
              countOfProblems: 34,
              correctRate: 100,
              nationalCorrectRate: 0,
            },
            {
              curriculumTitle: '제곱근과 실수',
              countOfProblems: 34,
              correctRate: 50,
              nationalCorrectRate: 100,
            },
          ],
        },
        {
          curriculumTitle: '초3-2',
          correctRate: 60,
          wrongRate: 40,
          data: [
            {
              curriculumTitle: '더하기와 빼기',
              countOfProblems: 93,
              correctRate: 47,
              nationalCorrectRate: 23,
            },
            {
              curriculumTitle: '엄청난 사칙연산',
              countOfProblems: 35,
              correctRate: 98,
              nationalCorrectRate: 82,
            },
            {
              curriculumTitle: '나누기를 나누면',
              countOfProblems: 12,
              correctRate: 34,
              nationalCorrectRate: 56,
            },
            {
              curriculumTitle: '나누기를 나누면',
              countOfProblems: 12,
              correctRate: 34,
              nationalCorrectRate: 56,
            },
            {
              curriculumTitle: '나누기를 나누면',
              countOfProblems: 12,
              correctRate: 34,
              nationalCorrectRate: 56,
            },
            {
              curriculumTitle: '나누기를 나누면',
              countOfProblems: 12,
              correctRate: 34,
              nationalCorrectRate: 56,
            },
          ],
        },
      ],
      scoring: {
        curriculums: {
          best: [
            {
              indices: [1, 2, 3],
              name: '제곱근의 뜻과 표현',
            },
            // {
            //   indices: [7, 10],
            //   name: '특수한 각의 삼각비의 도형에의 활용 최대글자수 테스트',
            // },
            {
              indices: [13],
              name: '강아지랑 고양이는 우열을 가리기 힘들 정도로 둘 다 귀엽다',
            },
          ],
          worst: [
            {
              indices: [4],
              name: '더하기보다 빼기가 어렵답니다',
            },
            {
              indices: [12],
              name: '특수한 각의 삼각비의 도형에의 활용 최대글자수 테스트',
            },
            {
              indices: [14, 15],
              name: '오늘 점심은 뭘 먹어야 잘 먹었다고 소문이 날까?',
            },
          ],
        },
        problems: (() =>
          Array.from({ length: 25 }, (_, i) => ({
            index: i + 1,
            answer: i % 5 === 0 ? '123' : '1',
            type: i % 5 === 0 ? 'MULTIPLE_CHOICE' : 'SINGLE_CHOICE',
            score: 3,
            curriculumName: '제곱근의 뜻과 표현',
            isCorrect: i % 2 === 0,
            nationalCorrectRate: 100,
          })))(),
      },
    },
    award: {
      uuid: '202211000221-0000656',
      type: 'GRAND_PRIZE', // | "gold-award" | "silver-award" | "bronze-award"
      name: '김부탁',
      academyName: 'develop선생님',
      examTitle: '[중1] 제2회 매쓰플랫 학력평가(2022년 11월)',
      examOpenYearMonth: '2022-11',
      examDoneDate: '2022-12-01',
      examRound: 1,
      examSchoolTypeAndGrade: '중1',
    },
  },
  MAAT: {
    award: {
      examId: 52,
      title: '제 0.1회 초등 경시대회 테스트 학습지',
      grade: 1,
      schoolType: 'MIDDLE',
      studentName: 'a3',
      tier: 'BRONZE_AWARD',
      uuid: '202405000121-1455380',
      round: 1,
      academyName: '개발팀-자유1',
      date: '2024-08-02',
    },
    academyAward: {
      uuid: '202211000221-0000656',
      round: 2,
      academyName: '일이삼사오육칠팔구십일이삼사오육칠팔_여기부터안보임',
      grade: 3,
      schoolType: 'ELEMENTARY',
      date: '2024-08-02',
    },
    report_0점: {
      type: null,
      uuid: '202407000113-1457751',
      studentName: '프리미엄28',
      academyName: 'testMAAT선생님',
      examTitle: '[초3] 제 2회 MAAT 수학 경시대회',
      examRound: 1,
      examSchoolType: 'ELEMENTARY',
      examGrade: 3,
      score: 0,
      repBigChapterName: [],
      weakBigChapterName: ['분수와 소수', '나눗셈', '길이와 시간'],
      categoryBehavior: [
        { name: '계산능력', totalScore: 25, averageScore: 1, score: 0 },
        { name: '이해능력', totalScore: 16, averageScore: 0, score: 0 },
        { name: '추론능력', totalScore: 27, averageScore: 1, score: 0 },
        { name: '문제해결능력', totalScore: 32, averageScore: 1, score: 0 },
      ],
      categoryUnit: [
        { name: '수와 연산', totalScore: 43, averageScore: 1, score: 0 },
        { name: '도형', totalScore: 19, averageScore: 1, score: 0 },
        { name: '측정', totalScore: 18, averageScore: 1, score: 0 },
        { name: '규칙성', totalScore: 11, averageScore: 1, score: 0 },
        { name: '자료와 가능성', totalScore: 9, averageScore: 1, score: 0 },
      ],
      problemSummary: [
        {
          number: 1,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '계산하기',
          score: 3,
          result: 'WRONG',
          average: 17,
        },
        {
          number: 2,
          chapter: '(2-2) 시각과 시간-심화',
          categoryCognitionName: '인식하기',
          score: 3,
          result: 'WRONG',
          average: 18,
        },
        {
          number: 3,
          chapter: '분수와 소수',
          categoryCognitionName: '상기하기',
          score: 3,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 4,
          chapter: '(2-2) 시각과 시간-심화',
          categoryCognitionName: '인식하기',
          score: 3,
          result: 'WRONG',
          average: 16,
        },
        {
          number: 5,
          chapter: '곱셈',
          categoryCognitionName: '결론 도출하기',
          score: 3,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 6,
          chapter: '길이와 시간',
          categoryCognitionName: '결론 도출하기',
          score: 3,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 7,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '분석하기',
          score: 3,
          result: 'WRONG',
          average: 14,
        },
        {
          number: 8,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 14,
        },
        {
          number: 9,
          chapter: '길이와 시간',
          categoryCognitionName: '측정하기',
          score: 4,
          result: 'WRONG',
          average: 13,
        },
        {
          number: 10,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '결론 도출하기',
          score: 4,
          result: 'WRONG',
          average: 14,
        },
        {
          number: 11,
          chapter: '평면도형',
          categoryCognitionName: '분류/정렬하기',
          score: 4,
          result: 'WRONG',
          average: 14,
        },
        {
          number: 12,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '실행하기',
          score: 4,
          result: 'WRONG',
          average: 14,
        },
        {
          number: 13,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 17,
        },
        {
          number: 14,
          chapter: '평면도형',
          categoryCognitionName: '실행하기',
          score: 4,
          result: 'WRONG',
          average: 16,
        },
        {
          number: 15,
          chapter: '곱셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 16,
        },
        {
          number: 16,
          chapter: '나눗셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 17,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '분석하기',
          score: 4,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 18,
          chapter: '곱셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 19,
          chapter: '평면도형',
          categoryCognitionName: '분류/정렬하기',
          score: 5,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 20,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '계산하기',
          score: 5,
          result: 'WRONG',
          average: 13,
        },
        {
          number: 21,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '분석하기',
          score: 5,
          result: 'WRONG',
          average: 12,
        },
        {
          number: 22,
          chapter: '평면도형',
          categoryCognitionName: '인식하기',
          score: 5,
          result: 'WRONG',
          average: 16,
        },
        {
          number: 23,
          chapter: '(2-2) 시각과 시간-심화',
          categoryCognitionName: '정보 찾아내기',
          score: 5,
          result: 'WRONG',
          average: 14,
        },
        {
          number: 24,
          chapter: '평면도형',
          categoryCognitionName: '실행하기',
          score: 5,
          result: 'WRONG',
          average: 15,
        },
        {
          number: 25,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '정보 찾아내기',
          score: 5,
          result: 'WRONG',
          average: 16,
        },
      ],
      roadMap:
        '모든 영역에 대하여 다시 한번 기본 실력을 다지는 과정이 필요한 프리미엄28 학생은 전반적으로 부족한 성적을 보이며 또한, 모든 인지적 행동 영역에서 다소 부족함을 보입니다. 각 문항에서 평가하는 문항인지요소 부분에 있어서는 이전에 배운 수학적 정의, 용어, 성질 등을 기억하고 상기할 수 있는 능력인 상기하기가 특히 부족한 것으로 보이므로 이를 기를 수 있는 학습을 추가로 진행하는 것을 추천합니다. 평가 내용에 있어서는 프리미엄28 학생은 모든 단원에 대하여 개념부터 확실히 익히는 과정이 필요하다고 보입니다.',
      rate: 67,
    },
    report: {
      type: 'PARTICIPATION_AWARD',
      uuid: '202405000121-1320252',
      studentName: '김회준_실서버_cbt1',
      academyName: '개발팀-정식가입',
      examTitle: '제 0.1회 초등 경시대회 테스트 학습지',
      examRound: 1,
      examSchoolType: 'ELEMENTARY',
      examGrade: 1,
      score: 0,
      repBigChapterName: ['평면도형', '덧셈과 뺄셈', '(2-2) 규칙 찾기-심화'],
      weakBigChapterName: ['길이와 시간', '나눗셈', '곱셈'],
      categoryBehavior: [
        {
          name: '계산능력',
          totalScore: 21,
          averageScore: 2,
          score: 11,
        },
        {
          name: '이해능력',
          totalScore: 14,
          averageScore: 1,
          score: 9,
        },
        {
          name: '추론능력',
          totalScore: 8,
          averageScore: 3,
          score: 0,
        },
        {
          name: '문제해결능력',
          totalScore: 8,
          averageScore: 2,
          score: 4,
        },
      ],
      categoryUnit: [
        {
          name: '수와 연산',
          totalScore: 24,
          averageScore: 2,
          score: 13,
        },
        {
          name: '도형',
          totalScore: 4,
          averageScore: 1,
          score: 4,
        },
        {
          name: '측정',
          totalScore: 12,
          averageScore: 2,
          score: 4,
        },
        {
          name: '규칙성',
          totalScore: 7,
          averageScore: 2,
          score: 3,
        },
        {
          name: '자료와 가능성',
          totalScore: 4,
          averageScore: 3,
          score: 0,
        },
      ],
      problemSummary: [
        {
          number: 1,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '계산하기',
          score: 2,
          result: 'WRONG',
          average: 50,
        },
        {
          number: 2,
          chapter: '길이와 시간',
          categoryCognitionName: '인식하기',
          score: 2,
          result: 'CORRECT',
          average: 50,
        },
        {
          number: 3,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '상기하기',
          score: 3,
          result: 'WRONG',
          average: 50,
        },
        {
          number: 4,
          chapter: '평면도형',
          categoryCognitionName: '인식하기',
          score: 3,
          result: 'CORRECT',
          average: 50,
        },
        {
          number: 5,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '결론 도출하기',
          score: 3,
          result: 'WRONG',
          average: 50,
        },
        {
          number: 6,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '결론 도출하기',
          score: 3,
          result: 'CORRECT',
          average: 50,
        },
        {
          number: 7,
          chapter: '평면도형',
          categoryCognitionName: '분석하기',
          score: 3,
          result: 'WRONG',
          average: 50,
        },
        {
          number: 8,
          chapter: '곱셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'CORRECT',
          average: 50,
        },
        {
          number: 9,
          chapter: '나눗셈',
          categoryCognitionName: '측정하기',
          score: 4,
          result: 'WRONG',
          average: 66,
        },
        {
          number: 10,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '결론 도출하기',
          score: 4,
          result: 'CORRECT',
          average: 33,
        },
        {
          number: 11,
          chapter: '곱셈',
          categoryCognitionName: '분류/정렬하기',
          score: 4,
          result: 'WRONG',
          average: 66,
        },
        {
          number: 12,
          chapter: '평면도형',
          categoryCognitionName: '실행하기',
          score: 4,
          result: 'CORRECT',
          average: 33,
        },
        {
          number: 13,
          chapter: '덧셈과 뺄셈',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 66,
        },
        {
          number: 14,
          chapter: '(2-2) 규칙 찾기-심화',
          categoryCognitionName: '실행하기',
          score: 4,
          result: 'CORRECT',
          average: 33,
        },
        {
          number: 15,
          chapter: '평면도형',
          categoryCognitionName: '계산하기',
          score: 4,
          result: 'WRONG',
          average: 50,
        },
      ],
      roadMap:
        '모든 영역에 대하여 다시 한번 기본 실력을 다지는 과정이 필요한 김회준_실서버_cbt1 학생은 평면도형, 덧셈과 뺄셈, (2-2) 규칙 찾기-심화 단원에서 교육과정의 기본 및 응용 문제를 해결할 수 있는 실력을 보였으나 모든 인지적 행동 영역에서 다소 부족함을 보입니다. 각 문항에서 평가하는 문항인지요소 부분에 있어서는 측정 도구를 사용하거나 측정 단위를 적절하게 선택할 수 있는 능력인 측정하기가 특히 부족한 것으로 보이므로 이를 기를 수 있는 학습을 추가로 진행하는 것을 추천합니다. 평가 내용에 있어서는 김회준_실서버_cbt1 학생은 더욱 향상된 수학 실력을 위해 길이와 시간, 나눗셈, 곱셈 단원에 대한 다양한 응용 문제를 포함하여 복습하는 과정을 추천합니다.',
      rate: 78.12,
    },
  },
}

export default CONSTANT
