import styled from '@emotion/styled'

const Page = styled.div`
  display: flex;
  padding: 0;
  margin: 1cm auto;
  border: 1px solid black;

  @media print {
    @page {
      margin: 0px;
    }

    zoom: 1.33;

    -webkit-print-color-adjust: exact;

    background: inherit !important;
    padding: 0mm !important;
    margin: 0mm !important;
    border: initial !important;
    width: initial !important;
    min-height: initial !important;
    page-break-after: always !important;

    .braek-inside-avoid {
      break-inside: avoid;
    }
  }
`

export default Page
