import { createRoot } from 'react-dom/client'

import App from './app'
import { customEventName, monitoringModule } from './common'
import React from 'react'

// monitoringModule.initialize()

createRoot(document.querySelector('#root')!).render(<App />)

window['setData'] = (data) => {
  const getPdfContentsEvent = new CustomEvent(customEventName.getPdfContents, {
    detail: data,
  })
  window.dispatchEvent(getPdfContentsEvent)
}
