import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

import { AbilityExamT } from '../..'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
ChartJS.defaults.font = {
  family: 'Pretendard',
}

type Props = {
  data: AbilityExamT['Report']
}

const 나의_점수_chart = ({ data }: Props) => {
  return (
    <Bar
      style={{ width: 258, height: 115 }}
      width={1032}
      height={460}
      options={{
        animation: {
          onComplete() {
            document.querySelector('#result')!.classList.add('나의_점수')
          },
        },
        layout: {
          padding: {
            right: 100,
          },
        },
        responsive: false,
        indexAxis: 'y',
        datasets: {
          bar: {
            barThickness: 56,
          },
        },
        scales: {
          x: {
            grid: {
              lineWidth: 4,
              tickColor: '#000',
              tickLength: 20,
              color: (context) => {
                return context.tick.label === '0점'
                  ? '#000'
                  : context.tick.label === '100점'
                    ? 'transparent'
                    : '#e8e8e8'
              },
            },
            ticks: {
              maxTicksLimit: 6,
              font: {
                size: 24,
                lineHeight: 2,
                weight: '700',
              },
              color: '#959595',
              callback: (v) => `${v}점`,
            },
          },
          y: {
            grid: {
              lineWidth: 4,
              tickLength: 20,
              tickColor: 'transparent',
              color: (context) => {
                return context.tick ? 'transparent' : '#000'
              },
            },
            ticks: {
              font: {
                size: 24,
                weight: '700',
              },
              color: ['#00abff', '#959595', '#959595'],
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      data={{
        labels: ['나의 점수', '전국 평균', [`전국 상위 `, `30% 평균`]],
        datasets: [
          {
            data: [data.score, data.national.average, data.national.averageOfTop30th],
            datalabels: {
              padding: 8,
              anchor: 'end',
              align: 'right',
              font: {
                size: 24,
                weight: 800,
              },
              color: ['#00ABFF', '#5c5c5c', '#5c5c5c'],
            },
            backgroundColor: ['#00ABFF', '#E8E8E8', '#E8E8E8'],
          },
        ],
      }}
    />
  )
}

export default 나의_점수_chart
