import z from 'zod'

const awardModel = z.object({
  uuid: z.string().regex(/\d-\d/),
  type: z.enum(['GRAND_PRIZE', 'GOLD_AWARD', 'SILVER_AWARD', 'BRONZE_AWARD']).nullable(),
  name: z.string().min(1),
  academyName: z.string().min(1),
  examTitle: z.string().min(1),
  examOpenYearMonth: z.string().regex(/\d-\d/),
  examDoneDate: z.string().regex(/\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/),
  examRound: z.number().gte(1),
  examSchoolTypeAndGrade: z.string().min(2),
})

type AwardT = z.infer<typeof awardModel>

export { awardModel }
export type { AwardT }
