import { MAATT } from '../..'
import S from '../Report.style'

type Props = { data: MAATT['ReportT'] }

const ProblemTable = ({ data }: Props) => {
  return (
    <article css={S.ProblemTable}>
      <div className="header">
        <div className="col1">
          <p>번호</p>
        </div>
        <div className="col2">
          <p>관련단원</p>
        </div>
        <div className="col3">
          <p>문항 인지 요소</p>
        </div>
        <div className="col4">
          <p>배점</p>
        </div>
        <div className="col5">
          <p>채점결과</p>
        </div>
        <div className="col6">
          <p>전국정답률</p>
        </div>
      </div>
      {data.problemSummary.map(
        ({ number, chapter, categoryCognitionName, score, result, average }) => {
          return (
            <div className="row" key={number}>
              <div className="col1">
                <p>{number}</p>
              </div>
              <div className="col2">
                <p>{chapter}</p>
              </div>
              <div className="col3">
                <p>{categoryCognitionName}</p>
              </div>
              <div className="col4">
                <p>{score}</p>
              </div>
              <div className="col5">
                <img
                  src={`${
                    import.meta.env.VITE_AWS_IMAGE_URL
                  }/ability-exam/report/${result === 'CORRECT' ? 'O' : 'X'}@3x.png`}
                />
              </div>
              <div className="col6">
                <p>{average}%</p>
              </div>
            </div>
          )
        },
      )}
    </article>
  )
}

export default ProblemTable
