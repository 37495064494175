import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router'
import { format } from 'date-fns'
import clsx from 'clsx'

import { PortraitPage } from 'src/component'
import { utility } from 'src/common'
import { useFontLoader, useImageLoader } from 'src/hooks'

import { AwardT } from './Award.model'
import S from './Award.style'

const translateType2KR = (type: AwardT['tier']) => {
  switch (type) {
    case 'GRAND_PRIZE':
      return '대 상'
    case 'GOLD_AWARD':
      return '금 상'
    case 'SILVER_AWARD':
      return '은 상'
    case 'BRONZE_AWARD':
      return '동 상'
    case 'PARTICIPATION_AWARD':
      return '장 려 상'
  }
}

const data2UIConverter = (
  data: AwardT,
): AwardT & {
  awardTypeKR: ReturnType<typeof translateType2KR>
  content: string[]
} => {
  const ellipsisAcademyName =
    data.academyName.length > 18 ? `${data.academyName.slice(0, 18)}...` : data.academyName

  const studentNameWithBlank = data.studentName.split('').join(' ')
  const ellipsisStudentName =
    studentNameWithBlank.length > 23
      ? `${studentNameWithBlank.slice(0, 23)}...`
      : studentNameWithBlank

  const awardType = data.tier
  const awardTypeKR = translateType2KR(awardType)

  const date = format(new Date(data.date), 'yyyy년 M월 d일')

  const defaultContent = [
    `위 학생은 한국수학교육학회에서 주최하는`,
    `제 ${data.round}회 MAAT 수학경시대회에서`,
    `우수한 성적을 거두었기에`,
    `이 상장을 수여합니다.`,
  ]
  const participationContent = [
    `위 학생은 한국수학교육학회에서 주최하는`,
    `제 ${data.round}회 MAAT 수학경시대회에`,
    `참가하였기에 이 상장을 수여합니다.`,
  ]
  const content = awardType === 'PARTICIPATION_AWARD' ? participationContent : defaultContent

  return {
    ...data,
    academyName: ellipsisAcademyName,
    studentName: ellipsisStudentName,
    awardTypeKR,
    date,
    content,
  }
}

const Award = () => {
  const [isLoadComplete, setIsLoadComplete] = useState(false)
  const { areFontsLoaded } = useFontLoader()
  const { containerRef, areImagesLoaded } = useImageLoader()

  const loadedData = useLoaderData() as AwardT
  const uiData = data2UIConverter(loadedData)

  useEffect(() => {
    if (areFontsLoaded && areImagesLoaded) {
      setIsLoadComplete(true)
    }
  }, [areFontsLoaded, areImagesLoaded])

  return (
    <PortraitPage css={S.Container} ref={containerRef}>
      <div css={S.Result} id="result" className={clsx(isLoadComplete && 'complete')} />
      <img css={S.BackgroundRibbon} src={utility.s3URL('MAAT/award/BackgroundRibbon@3x.png')} />
      <img
        css={S.BackgroundMAATLogo}
        src={utility.s3URL('MAAT/award/MAATLogo@3x.png')}
        alt="MAAT Logo"
      />
      <img
        css={S.AbsoluteMedal}
        src={utility.s3URL(`MAAT/award/${uiData.tier}@3x.png`)}
        alt="Medal"
      />

      <div css={S.Wrapper}>
        <header>
          <label>{uiData.uuid}</label>
          {uiData.awardTypeKR}
        </header>
        <article css={S.MiddleArticle}>
          <p className="title">초등학교 {uiData.grade}학년 부문</p>
          <p className="academyName">{uiData.academyName}</p>
          <p className="studentName">{uiData.studentName}</p>
        </article>
        <article css={S.ContentArticle}>
          <p>
            {uiData.content.map((content, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <br />}
                {content}
              </React.Fragment>
            ))}
          </p>
          <label css={S.SemiBoldFont}>{uiData.date}</label>
        </article>
        <footer css={S.Footer}>
          <div className="left">
            <p>
              <b css={S.ExtraBold}>주최</b>
              <span>한국수학교육학회</span>
            </p>
            <p>
              <b css={S.ExtraBold}>후원</b>
              <span>매쓰플랫</span>
            </p>
          </div>
          <div className="right">
            <img src={utility.s3URL('MAAT/award/KSMELogo@3x.png')} alt="KSME Logo" />
            <img src={utility.s3URL('MATHFLAT@3x.png')} alt="Mathflat Logo" />
          </div>
        </footer>
      </div>
    </PortraitPage>
  )
}

export default Award
