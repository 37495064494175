import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { colors } from 'src/style'

const S = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 34px 40px 34px 40px;
  `,
  Layout: css`
    &.info {
      margin: 0 0 34px 0;
    }
  `,
  
  Result: css`
    display: none;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -10;

    &.complete {
      display: block
    }
  `,

  진단결과및분석Wrapper: css`
    display: flex;
    flex-direction: column;
    gap: 22px;

    > h5 {
      color: ${colors.gray.$900};
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: -0.028px;
    }

    > main {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }
  `,

  ProblemTable: css`
    > .header {
      display: flex;
      height: 14px;
      background: ${colors.gray.$200};

      > div {
        display: flex;
        align-items: center;

        color: ${colors.gray.$900};
        font-size: 10px;
        line-height: 12px;

        > p {
          zoom: 0.7;
        }
      }
    }

    .row {
      display: flex;
      height: 20px;
      border-bottom: 0.5px solid ${colors.gray.$300};

      > div {
        display: flex;
        align-items: center;

        text-align: center;
        color: #000;
        font-size: 10px;
        line-height: 12px;
      }

      > div > p:not(.col5) {
        zoom: 0.8;
      }
    }

    .col1 {
      justify-content: center;
      width: 30px;
    }
    .col2 {
      width: 181px;
    }
    .col3 {
      width: 200px;
    }
    .col4 {
      justify-content: center;
      width: 20px;
    }
    .col5 {
      justify-content: center;
      width: 36px;

      > img {
        width: 12px;
        height: 12px;
      }
    }
    .col6 {
      justify-content: center;
      width: 48px;
    }
  `,
  RoadMap: css`
    border: 1px solid ${colors.gray.$300};
    border-radius: 4px;

    margin-top: 6px;

    > header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${colors.gray.$300};

      color: ${colors.gray.$900};
      font-size: 10px;
      line-height: 18px;
      border-bottom: 1px solid ${colors.gray.$300};
    }

    > div {
      font-size: 10px;
      line-height: 210%;
      padding: 8px 12px;

      > p {
        zoom: 0.9;

        color: #000;
      }
    }
  `,

  Footer: css`
    display: flex;
    justify-content: center;
    font-size: 9px;
    line-height: 10.21px;

    position: absolute;
    bottom: 33px;
    left: 50%;
    transform: translateX(-50%);
  `,
}

export default S
