import { useEffect, useState } from 'react'

const useFontLoader = () => {
  const [areFontsLoaded, setAreFontsLoaded] = useState(false)

  useEffect(() => {
    document.fonts.ready
      .then(() => {
        setAreFontsLoaded(true)
      })
      .catch((error) => {
        // Sentry ?
      })
  }, [])

  return { areFontsLoaded }
}
export default useFontLoader
