import styled from '@emotion/styled'
import { typo } from 'src/style'

const S = {
  Result: styled.div`
    display: none;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -10;

    &.completed {
      display: block;
    }
  `,
  Wrapper: styled.div`
    width: 100%;
    height: 100%;

    > header {
      display: flex;
      justify-content: space-between;
      padding: 2px 0 50px 0;
      ${typo.caption01};
      color: #000;
    }

    > main {
      display: flex;
      justify-content: space-between;
      padding: 0 0 52px 0;
    }
  `,
  HeaderRight: styled.div`
    display: flex;
    align-items: flex-start;

    .freewheelin-logo {
      width: 90px;
      margin-right: 17px;
    }
    .mathflat-logo {
      width: 60px;
    }
  `,
  MainLeft: styled.div`
    padding: 0px 16px 0 0;

    > .title {
      ${typo.heading02};
      font-weight: 800;
      padding: 0 0 34px 0;
    }
    > .row {
      display: flex;
      ${typo.caption01};
      margin: 0 0 10px 0;
      > b {
        width: 70px;
        font-weight: 700;
        margin-right: 10px;
      }
    }
    > .content {
      padding: 40px 0 0 0;
      color: #000;

      ${typo.body02};
    }
  `,
  Footer: styled.footer`
    display: flex;
    align-items: center;

    > .mathflat-signature {
      margin-left: 15px;
      width: 74px;
    }
  `,
}

export default S
