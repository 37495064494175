import React, { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router'
import { PortraitPage } from 'src/component'
import clsx from 'clsx'

import { useFontLoader, useImageLoader } from 'src/hooks'

import { MAATT } from '..'
import { Header, Info, ProblemTable, RoadMap, RepAndWeakChapter, CategoryGraph } from './component'
import S from './Report.style'

export const translateTier2KR = (tier: MAATT['ReportT']['type']) => {
  switch (tier) {
    case 'GRAND_PRIZE':
      return '대상'
    case 'GOLD_AWARD':
      return '금상'
    case 'SILVER_AWARD':
      return '은상'
    case 'BRONZE_AWARD':
      return '동상'
    case 'PARTICIPATION_AWARD':
      return '장려상'
  }
}

const Report = () => {
  const [isLoadComplete, setIsLoadComplete] = useState(false)
  const { areFontsLoaded } = useFontLoader()
  const { containerRef, areImagesLoaded } = useImageLoader()

  const data = useLoaderData() as MAATT['ReportT']

  useEffect(() => {
    if (areFontsLoaded && areImagesLoaded) {
      setIsLoadComplete(true)
    }
  }, [areFontsLoaded, areImagesLoaded])

  return (
    <>
      {/* 
        #result 컴포넌트가 visibility: visible / display: true로 적용될 때 까지 pptr가 기다림 (waitForSelector) 
        이후 해당 컴포넌트의 classList에 completed가 있다면 성공 / failed가 있다면 실패 로직을 태움 (pptr 로직)
      */}
      <PortraitPage ref={containerRef}>
        <div css={S.Result} id="result" className={clsx(isLoadComplete && 'complete')} />
        <S.Wrapper className="break-inside-avoid">
          <Header data={data} />
          <article css={S.Layout} className="info">
            <Info data={data} />
          </article>

          <article css={S.진단결과및분석Wrapper}>
            <h5>진단 결과 및 분석</h5>
            <main>
              <RepAndWeakChapter data={data} />
              <CategoryGraph data={data} />
            </main>
          </article>
          <footer css={S.Footer}>1/2</footer>
        </S.Wrapper>
      </PortraitPage>

      <PortraitPage>
        <S.Wrapper>
          <Header data={data} />
          <ProblemTable data={data} />
          <RoadMap data={data} />
          <footer css={S.Footer}>2/2</footer>
        </S.Wrapper>
      </PortraitPage>
    </>
  )
}

export default Report
