import { useEffect, useRef, useState } from 'react'

const useImageLoader = () => {
  const [areImagesLoaded, setAreImagesLoaded] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current) return

    const imgElements = containerRef.current.querySelectorAll('img')
    const totalImages = imgElements.length
    let loadedImages = 0

    if (totalImages === 0) {
      setAreImagesLoaded(true)
      return
    }

    const handleImageLoad = () => {
      loadedImages += 1
      if (loadedImages === totalImages) {
        setAreImagesLoaded(true)
      }
    }

    imgElements.forEach((img) => {
      if (img.complete) {
        handleImageLoad()
      } else {
        img.addEventListener('load', handleImageLoad)
        img.addEventListener('error', handleImageLoad)
      }
    })

    return () => {
      imgElements.forEach((img) => {
        img.removeEventListener('load', handleImageLoad)
        img.removeEventListener('error', handleImageLoad)
      })
    }
  }, [])

  return { containerRef, areImagesLoaded }
}

export default useImageLoader
