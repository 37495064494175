import { useEffect, useRef } from 'react'
import { useLoaderData } from 'react-router'
import { LandscapePage } from 'src/component'

import { CertificationT } from './Certification.model'
import S from './Certification.style'

const Certification = () => {
  const data = useLoaderData() as CertificationT

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      ref.current?.classList.add('completed')
    }, 1500)
  }, [])

  return (
    <LandscapePage>
      <S.Result id="result" ref={ref} />
      <S.Wrapper
        style={{
          padding: '50px 64px',
        }}
      >
        <header>
          <p>인증번호 : {data.uuid}</p>
          <S.HeaderRight>
            <img
              className="freewheelin-logo"
              src={`${import.meta.env.VITE_AWS_IMAGE_URL}/FREEWHEELIN@3x.png`}
              alt="프리윌린 로고"
            />
            <img
              className="mathflat-logo"
              src={`${import.meta.env.VITE_AWS_IMAGE_URL}/MATHFLAT@3x.png`}
              alt="매쓰플랫 로고"
            />
          </S.HeaderRight>
        </header>
        <main>
          <S.MainLeft>
            <h1 className="title">
              {data.type === 'superior' ? '매쓰플랫 성적 우수 인증서' : '매쓰플랫 학력평가 인증서 '}
            </h1>
            <div className="row">
              <b>・ 이름</b>
              <p>{data.name}</p>
            </div>
            <div className="row">
              <b>・ 소속</b>
              <p>{data.academyName}</p>
            </div>
            <div className="row">
              <b>・ 응시년월</b>
              <p>{`${data.year}-${data.month}`}</p>
            </div>
            <div className="row">
              <b>・ 시험명</b>
              <p>{data.examTitle}</p>
            </div>
            <div className="row">
              <b>・ 점수 / 등급</b>
              <p>
                {data.score}점 / {data.tier}
              </p>
            </div>
            {data && (
              <p className="content">
                {data.type === 'superior'
                  ? `위 사람은 프리윌린 수학연구소에서 주관하는 ${data.examTitle}에서 우수한 성적을 거두었기에 이 증서를 수여합니다.`
                  : `위 사람은 프리윌린 수학연구소에서 주관하는 ${data.examTitle}에서 위와 같은 성적을 거두었음을 인증합니다.`}
              </p>
            )}
          </S.MainLeft>
          <div className="main-right">
            <img
              src={`${
                import.meta.env.VITE_AWS_IMAGE_URL
              }/ability-exam/certification/${encodeURIComponent(data.tier)}@3x.png`}
              alt="등급 뱃지"
              width={210}
            />
          </div>
        </main>
        <S.Footer>
          <p>(주) 프리윌린 수학연구소</p>
          <img
            className="mathflat-signature"
            src={`${import.meta.env.VITE_AWS_IMAGE_URL}/SIGNATURE@3x.png`}
            alt="주식회사프리윌린인"
          />
        </S.Footer>
      </S.Wrapper>
    </LandscapePage>
  )
}

export default Certification
