import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { AbilityExam, ErrorPage, MAAT } from '../domain'
import { defaultLoader, routeName } from '.'

const PdfClientRouter = () => {
  const router = createBrowserRouter([
    {
      path: routeName.root,
      errorElement: <ErrorPage />,
      children: [
        {
          path: routeName.학력평가_상장,
          loader: defaultLoader,
          element: <AbilityExam.AwardPage />,
        },
        {
          path: routeName.학력평가_인증서,
          loader: defaultLoader,
          element: <AbilityExam.CertificationPage />,
        },
        {
          path: routeName.학력평가_결과지,
          loader: defaultLoader,
          element: <AbilityExam.ReportPage />,
        },
        {
          path: routeName.MAAT_상장,
          loader: defaultLoader,
          element: <MAAT.AwardPage />,
        },
        {
          path: routeName.MAAT_기관상장,
          loader: defaultLoader,
          element: <MAAT.AcademyAwardPage />,
        },
        {
          path: routeName.MAAT_결과지,
          loader: defaultLoader,
          element: <MAAT.ReportPage />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default PdfClientRouter
