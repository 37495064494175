import { format } from 'date-fns'
import { useEffect, useRef } from 'react'
import { useLoaderData } from 'react-router'
import { PortraitPage } from 'src/component'

import { AwardT } from './Award.model'
import S from './Award.style'

const translateType2KR = (type: AwardT['type']) => {
  switch (type) {
    case 'GRAND_PRIZE':
      return '대'
    case 'GOLD_AWARD':
      return '금'
    case 'SILVER_AWARD':
      return '은'
    case 'BRONZE_AWARD':
      return '동'
  }
}

const Award = () => {
  const ref = useRef<HTMLDivElement>(null)
  const data = useLoaderData() as AwardT

  const awardTypeKR = translateType2KR(data.type)

  useEffect(() => {
    setTimeout(() => {
      ref.current?.classList.add('completed')
    }, 1500)
  }, [])

  return (
    <PortraitPage>
      <S.Result id="result" ref={ref} />
      <S.Wrapper
        style={{
          padding: '50px 46px',
          backgroundImage: `url('${
            import.meta.env.VITE_AWS_IMAGE_URL
          }/ability-exam/award/award-background%403x.png')`,
          backgroundSize: '551px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '24px 22px',
        }}
      >
        <header>
          <p>{data.uuid}</p>
          <S.HeaderRight>
            <img
              className="freewheelin-logo"
              src={`${import.meta.env.VITE_AWS_IMAGE_URL}/FREEWHEELIN@3x.png`}
              alt="프리윌린 로고"
            />
            <img
              className="mathflat-logo"
              src={`${import.meta.env.VITE_AWS_IMAGE_URL}/MATHFLAT@3x.png`}
              alt="매쓰플랫 로고"
            />
          </S.HeaderRight>
        </header>
        <main>
          <S.Title>
            <span>
              제{data.examRound}회 매쓰플랫 전국 중등 학력평가&nbsp;
              <div className="divider" />
              &nbsp;
              {data.examSchoolTypeAndGrade}
            </span>
            <h1>{awardTypeKR}&nbsp;&nbsp;&nbsp;상</h1>
          </S.Title>
          <S.Info>
            <div className="row">
              <p className="student-name category">{data.name}</p>
            </div>
            <div className="row">
              <p className="category">소속</p>
              <p>{data.academyName}</p>
            </div>
            <div className="row">
              <p className="category">응시년월</p>
              <p>{format(new Date(data.examOpenYearMonth), 'yyyy.M')}</p>
            </div>
          </S.Info>
          <S.Medal
            width={170}
            src={`${import.meta.env.VITE_AWS_IMAGE_URL}/ability-exam/award/${data.type}%403x.png`}
          />
          <S.Content>
            <p className="content">
              &nbsp;위 사람은 프리윌린 수학연구소에서 주관하는
              <br />
              {data.examTitle}에서
              <br />
              우수한 성적을 거두었기에 이 상장을 수여합니다.
            </p>
            <p className="done-date">{format(new Date(data.examDoneDate), 'yyyy년 M월 d일')}</p>
          </S.Content>
        </main>
        <S.Footer>
          <p>(주) 프리윌린 수학연구소</p>
          <img
            className="mathflat-signature"
            src={`${import.meta.env.VITE_AWS_IMAGE_URL}/SIGNATURE@3x.png`}
            alt="주식회사프리윌린인"
          />
        </S.Footer>
      </S.Wrapper>
    </PortraitPage>
  )
}

export default Award
