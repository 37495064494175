import styled from '@emotion/styled'
import { colors } from 'src/style'

import { AbilityExamT } from '../..'

type Props = {
  data: AbilityExamT['Report']
}
const Header = ({ data }: Props) => {
  const [grade, ...splittedTitle] = data.examTitle.split(' ')
  const title = splittedTitle.join(' ')

  return (
    <S.Header>
      <span>
        <b>{grade}</b>&nbsp;{title}
      </span>
      <div className="line" />
    </S.Header>
  )
}

const S = {
  Header: styled.header`
    position: relative;
    width: 100%;
    font-size: 12px;
    line-height: 14.4px;
    color: ${colors.gray.$800};

    > span {
      position: relative;
      z-index: 1;
      padding: 0 10px 0 0;
      background: #fff;

      > b {
        font-weight: bold;
      }
    }

    > .line {
      position: absolute;
      right: -40px;
      top: 7px;
      width: 100%;
      height: 1px;
      background: ${colors.gray.$500};
    }
  `,
}

export default Header
