import z from 'zod'

const awardModel = z.object({
  uuid: z.string().regex(/\d-\d/),
  examId: z.number(),
  title: z.string(),
  grade: z.number().gte(1).lte(6),
  schoolType: z.enum(['ELEMENTARY', 'MIDDLE', 'HIGH']),
  round: z.number().gte(1),
  academyName: z.string().min(1),
  studentName: z.string(),
  tier: z.enum([
    'GRAND_PRIZE',
    'GOLD_AWARD',
    'SILVER_AWARD',
    'BRONZE_AWARD',
    'PARTICIPATION_AWARD',
  ]),
  date: z.string().regex(/\d{4}-\d{2}-\d{2}/),
})

type AwardT = z.infer<typeof awardModel>

export { awardModel }
export type { AwardT }
