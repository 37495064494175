import { css } from '@emotion/react'

import fontSize from './fontSize'

// css 속성 돌면서 객체 만들어주는 앱니다 밑에 mt, mb 들 참고.. -회준
// const cssFactory = <T extends string, cssPropertyT extends keyof React.CSSProperties>(
//   cssPropertyNames: Array<cssPropertyT> | cssPropertyT,
//   valueObject: { [keys in T]: string }
// ) => {
//   const cssObject: Partial<{ [keys in T]: SerializedStyles }> = {}
//
//   if (Array.isArray(cssPropertyNames)) {
//     // 다중 속성일때
//     for (const key in valueObject) {
//       const styleObj: Partial<{ [keys in cssPropertyT]: string | number }> = {}
//       for (const cssProperty of cssPropertyNames) {
//         styleObj[cssProperty] = valueObject[key]
//       }
//       cssObject[key] = css`
//         ${styleObj}
//       `
//     }
//   } else {
//     // 단일 속성일때
//     for (const key in valueObject) {
//       cssObject[key] = css`
//         ${{ [cssPropertyNames]: valueObject[key] }};
//       `
//     }
//   }
//
//   return cssObject as { [keys in T]: SerializedStyles }
// }

export const typo = {
  heading01: css`
    font-size: ${fontSize.heading01};
    line-height: 62px;
    letter-spacing: -0.01em;
  `,
  heading02: css`
    font-size: ${fontSize.heading02};
    line-height: 40px;
    letter-spacing: -0.0098em;
  `,
  heading03: css`
    font-size: ${fontSize.heading03};
    line-height: 36px;
    letter-spacing: -0.015em;
  `,
  heading04: css`
    font-size: ${fontSize.heading04};
    line-height: 28px;
    letter-spacing: -0.01em;
  `,
  heading05: css`
    font-size: ${fontSize.heading05};
    line-height: 24px;
    letter-spacing: -0.01em;
  `,
  body01: css`
    font-size: ${fontSize.body01};
    line-height: 24px;
    letter-spacing: -0.01em;
  `,
  body02: css`
    font-size: ${fontSize.body02};
    line-height: 21px;
    letter-spacing: -0.002em;
  `,
  caption01: css`
    font-size: ${fontSize.caption01};
    line-height: 18px;
  `,
  caption02: css`
    font-size: ${fontSize.caption02};
    line-height: 12px;
  `,
}

export const elevation = {
  $01: css`
    box-shadow:
      0px 2px 6px rgba(41, 42, 43, 0.08),
      0px 1px 0px rgba(41, 42, 43, 0.04);
  `,
  $02: css`
    box-shadow:
      0px 4px 8px rgba(41, 42, 43, 0.08),
      0px 12px 16px rgba(41, 42, 43, 0.05);
  `,
  $03: css`
    box-shadow: 0px 12px 16px rgba(41, 42, 43, 0.1);
  `,
} as const
