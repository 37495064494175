const routeName = {
  // common
  root: '/',

  // 학력평가
  학력평가: '/ability-exam',
  학력평가_상장: `/ability-exam/award`,
  학력평가_인증서: `/ability-exam/certification`,
  학력평가_결과지: `/ability-exam/report`,

  // MAAT
  MAAT: 'MAAT',
  MAAT_상장: `/MAAT/award`,
  MAAT_기관상장: `/MAAT/academy-award`,
  MAAT_결과지: `/MAAT/report`,
} as const

export type RoutePathUnion = (typeof routeName)[keyof typeof routeName]

export default routeName
