import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

import { AbilityExamT } from '../..'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
ChartJS.defaults.font = {
  family: 'Pretendard',
}

type Props = { data: AbilityExamT['Report']; index: number }

const 단원별_정답률_chart = ({ data: _data, index }: Props) => {
  const { correctRate, curriculumTitle, data, wrongRate } = _data.correctRateByCurriculum[index]

  return (
    <Bar
      style={{ width: 500, height: 184 }}
      width={2000}
      height={736}
      plugins={[
        {
          // id는 Global에서 Plugin을 선언하고 Local에서 사용할 때 사용하지만,
          //  Local에서 선언 및 사용할 때에는 기입할 필요가 없다. (그러나 required. 대체 왜??)
          id: '',
          beforeInit(chart: any) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit

            // Override the fit function
            chart.legend.fit = function fit() {
              // Call original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)()
              // Change the height as suggested in another answers
              this.height += 40
            }
          },
        },
        {
          id: '',
          afterInit(chart) {
            const $wrapperDiv = document.createElement('div')
            const $b_curriculum = document.createElement('b')
            const $innerDiv = document.createElement('div')
            const $p_정답률_text = document.createElement('p')
            const $p_정답률_value = document.createElement('p')
            const $p_오답률_text = document.createElement('p')
            const $p_오답률_value = document.createElement('p')

            $b_curriculum.textContent = curriculumTitle
            $p_정답률_text.textContent = '정답률\u00a0:\u00a0'
            $p_정답률_value.textContent = `${correctRate}%`
            $p_오답률_text.textContent = '오답률\u00a0:\u00a0'
            $p_오답률_value.textContent = `${wrongRate}%`

            $b_curriculum.style.fontSize = '10px'
            $b_curriculum.style.fontWeight = '700'

            $wrapperDiv.className = 'chart-title'
            Object.assign($wrapperDiv.style, {
              display: 'flex',
              position: 'absolute',
              top: '26px',
              left: '50%',
              transform: 'translateX(-50%)',
            })

            Object.assign($innerDiv.style, {
              display: 'flex',
              marginTop: '1px',
            })

            Object.assign($p_정답률_text.style, {
              color: '#5C5C5C',
              fontSize: '10px',
              marginLeft: '4px',
              ['zoom']: '0.8',
            })

            Object.assign($p_정답률_value.style, {
              color: '#00ABFF',
              fontSize: '10px',
              marginRight: '5px',
              ['zoom']: '0.8',
            })

            Object.assign($p_오답률_text.style, {
              color: '#5C5C5C',
              fontSize: '10px',
              ['zoom']: '0.8',
            })

            Object.assign($p_오답률_value.style, {
              color: '#F19537',
              fontSize: '10px',
              ['zoom']: '0.8',
            })

            $wrapperDiv.appendChild($b_curriculum)
            $wrapperDiv.appendChild($innerDiv)
            $innerDiv.appendChild($p_정답률_text)
            $innerDiv.appendChild($p_정답률_value)
            $innerDiv.appendChild($p_오답률_text)
            $innerDiv.appendChild($p_오답률_value)

            chart.canvas.parentNode?.appendChild($wrapperDiv)
          },
        },
      ]}
      options={{
        animation: {
          onComplete() {
            document.querySelector('#result')!.classList.add(`단원별_정답률${index}`)
          },
        },
        borderColor: 'transparent',
        responsive: false,
        scales: {
          x: {
            grid: {
              lineWidth: 4,
              tickLength: 16,
              color: (context) => (context.index === 0 ? '#000' : 'transparent'),
            },
            ticks: {
              color: '#5C5C5C',
              font: {
                size: 24,
                weight: '700',
                family: 'Pretendard',
              },
            },
          },
          y: {
            min: 0,
            max: 100,
            grid: {
              z: -2,
              lineWidth: 4,
              tickLength: 16,
              tickColor: 'transparent',
              color: (context) => (context.index === 0 ? '#000' : '#e8e8e8'),
            },
            ticks: {
              color: '#959595',
              maxTicksLimit: 6,
              font: {
                size: 24,
                weight: '700',
                family: 'Pretendard',
              },
              callback: (v) => `${v}%`,
            },
          },
        },
        plugins: {
          legend: {
            align: 'start',
            rtl: true,
            reverse: true,
            labels: {
              padding: 16,
              boxWidth: 16,
              boxHeight: 16,

              font: {
                size: 32,
                weight: '700',
                family: 'Pretendard',
              },
              color: '#00ABFF',
              generateLabels: () => [
                {
                  text: '내 정답률',
                  fontColor: '#00ABFF',
                  fillStyle: '#00ABFF',
                  lineWidth: 0,
                },
                {
                  text: '전국평균',
                  fontColor: '#707070',
                  fillStyle: '#E8E8E8',
                  lineWidth: 0,
                },
              ],
            },
          },
        },
      }}
      data={{
        labels: [
          ...data.map(({ curriculumTitle, countOfProblems }) => [
            curriculumTitle,
            `${countOfProblems}문항`,
          ]),
        ],
        datasets: [
          {
            label: '내 정답률',
            barThickness: 56,
            backgroundColor: '#00ABFF',
            data: data.map(({ correctRate }) => correctRate),
            datalabels: {
              anchor: 'end',
              align: 'top',
              color: '#00ABFF',
              font: {
                size: 24,
                weight: 700,
                family: 'Pretendard',
              },
            },
          },
          {
            // gap을 위한 data
            label: '',
            backgroundColor: 'transparent',
            maxBarThickness: 12,
            data: data.map(() => 0),
          },
          {
            label: '전국평균',
            barThickness: 56,
            backgroundColor: '#E8E8E8',
            data: data.map(({ nationalCorrectRate }) => nationalCorrectRate),
            datalabels: {
              anchor: 'end',
              align: 'top',
              color: '#5C5C5C',
              font: {
                size: 24,
                weight: 700,
                family: 'Pretendard',
              },
            },
          },
        ],
      }}
    />
  )
}

export default 단원별_정답률_chart
