import { css } from '@emotion/react'

const textEllipsis = (line: number) => css`
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  -webkit-line-clamp: ${line};
  -webkit-box-orient: vertical;
`

export default textEllipsis
