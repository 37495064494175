import { colors } from 'src/style'

import { MAATT } from '../..'
import { css } from '@emotion/react'
import { translateTier2KR } from '../Report'

type Props = {
  data: MAATT['ReportT']
}

const Info = ({ data }: Props) => {
  const academyName =
    data.academyName.length > 16 ? `${data.academyName.slice(0, 16)}...` : data.academyName
  const studentName =
    data.studentName.length > 15 ? `${data.studentName.slice(0, 15)}...` : data.studentName

  const isTypeNull = data.type === null

  return (
    <div css={S.Info}>
      <div className="title-wrapper">
        <h1 className="title">RESULT</h1>
        <span className="sub-title">진단결과</span>
      </div>
      <div className="info">
        <div css={S.DefaultInfo}>
          <div className="academy">
            <b>학원명</b>
            <div className="divider" />
            <p>{academyName}</p>
          </div>
          <div className="student">
            <b>학생이름</b>
            <div className="divider" />
            <p>{studentName}</p>
          </div>
        </div>
        <section css={S.ExamInfo}>
          <div css={S.ExamInfoLabel}>
            <p>내 점수</p>
          </div>
          <span className="score">
            <p>{data.score}</p>
            <div>
              <p>점</p>
            </div>
          </span>
          <div css={S.ExamInfoLabel}>
            <p>전국 순위</p>
          </div>
          <span className="percentile">
            <div>
              <p>상위</p>
            </div>
            <p>{data.rate}</p>
            <div>
              <p>%</p>
            </div>
          </span>
          <div css={S.ExamInfoLabel}>
            <p>
              수상 내역
              {data.type !== null && (
                <>
                  <br />({translateTier2KR(data.type)})
                </>
              )}
            </p>
          </div>
          <div className="tier">
            {data.type !== null && (
              <img
                src={`${
                  import.meta.env.VITE_AWS_IMAGE_URL
                }/MAAT/report/${encodeURIComponent(data.type)}@3x.png`}
                width={80}
              />
            )}
          </div>
        </section>
      </div>
    </div>
  )
}

const S = {
  Info: css`
    .title-wrapper {
      display: flex;
      gap: 6px;
      align-items: center;
      margin-bottom: 8px;

      .title {
        color: ${colors.gray.$900};
        font-weight: 700;
        font-size: 28px;
        line-height: normal;
      }
      .sub-title {
        color: ${colors.gray.$900};
        font-size: 12px;
        line-height: 14.4px;
      }
    }

    .info {
      width: 515px;
    }
  `,
  DefaultInfo: css`
    display: flex;
    font-size: 10px;
    line-height: 12px;
    border: 1px solid ${colors.gray.$400};
    border-radius: 6px 6px 0 0;
    background: ${colors.gray.$300};

    b {
      font-weight: bold;
    }
    .divider {
      margin: 0 20px;
      width: 1px;
      height: 8px;
      background: ${colors.gray.$900};
    }

    .academy,
    .student {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 7px 0 7px 20px;
    }
    .student {
      border-left: 1px solid ${colors.gray.$400};
    }
  `,
  ExamInfo: css`
    display: flex;
    height: 60px;
    border: 1px solid ${colors.gray.$400};
    border-top: none;

    .score,
    .percentile {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      flex: 0 0 133px;

      border-right: 1px solid ${colors.gray.$400};

      > p:first-of-type {
        font-size: 20px;
        font-weight: 700;
        line-height: normal;
      }
      > div > p {
        font-size: 10px;
        zoom: 0.8;
        line-height: normal;
      }
    }

    .tier {
      display: flex;
      flex: 1;
      justify-content: center;

      > img {
        position: absolute;
        margin-top: -2px;
      }
    }
  `,
  ExamInfoLabel: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;

    height: 100%;
    padding: 8px;
    background: ${colors.gray.$200};
    border-right: 1px solid ${colors.gray.$300};

    > p {
      font-size: 10px;
      font-weight: 700;
      zoom: 0.8;
    }
  `,
}

export default Info
