import clsx from 'clsx'
import { utility } from 'src/common'

import { AbilityExamT } from '../..'
import { ReportT } from '../Report.model'
import S from '../Report.style'

type Props = { data: AbilityExamT['Report'] }

const BestWorstInfo = ({
  best,
  worst,
  score,
}: Pick<Props['data']['scoring']['curriculums'], 'best' | 'worst'> & {
  score: Props['data']['score']
}) => {
  if (score === 0 || score === 100) {
    return (
      <S.BestWorstInfo className={clsx(score === 0 && 'lowest', score === 100 && 'highest')}>
        <div className="sub-title">
          <h3 className="best">대표 우수 단원</h3>
          <h3 className="worst">대표 취약 단원</h3>
        </div>
        {score === 0 ? (
          <p>0점 학생에게는 우수, 취약 단원이 노출되지 않습니다.</p>
        ) : (
          <p>축하합니다! 100점 학생에게는 우수, 취약 단원이 노출되지 않습니다.</p>
        )}
      </S.BestWorstInfo>
    )
  }

  const bestArray = [...best, null, null, null].splice(0, 3)
  const worstArray = [...worst, null, null, null].splice(0, 3)

  return (
    <S.BestWorstInfo>
      <div className="sub-title">
        <h3 className="best">대표 우수 단원</h3>
        <h3 className=" worst">대표 취약 단원</h3>
      </div>
      <div className="info-table">
        <div className="col best">
          {bestArray.map((best, index) => (
            <div className="row" key={index}>
              <p>{best?.name || '-'}</p>
            </div>
          ))}
        </div>
        <div className="col worst">
          {worstArray.map((worst, index) => (
            <div className="row" key={index}>
              <p>{worst?.name || '-'}</p>
            </div>
          ))}
        </div>
      </div>
    </S.BestWorstInfo>
  )
}

const 단원별_진단 = ({ data }: Props) => {
  const { best, worst } = data.scoring.curriculums

  const bestIndices = data.scoring.curriculums.best.map(({ indices }) => indices).flat()
  const worstIndices = data.scoring.curriculums.worst.map(({ indices }) => indices).flat()

  const getCircledAnswer = (
    type: ReportT['scoring']['problems'][number]['type'],
    answers: string,
  ) => {
    switch (type) {
      case 'SINGLE_CHOICE':
        // eslint-disable-next-line no-case-declarations
        const [result] = utility.convertToCircleNumber(Number(answers))
        return result
      case 'MULTIPLE_CHOICE':
        // eslint-disable-next-line no-case-declarations
        const numberAnswers = answers.split(',').map((answer) => Number(answer))
        return utility.convertToCircleNumber(...numberAnswers).join(',')
      default:
        return answers
    }
  }

  return (
    <S.Layout className="judgment">
      <S.Judgment>
        <h2 className="title">5. 단원별 진단</h2>
        <div className="chapter-wrapper">
          <BestWorstInfo best={best} worst={worst} score={data.score} />
        </div>
        <div className="problem-table">
          <div className="header">
            <div className="index">
              <p>번호</p>
            </div>
            <div className="answer">
              <p>정답</p>
            </div>
            <div className="is-correct">
              <p>채점결과</p>
            </div>
            <div className="chapter">
              <p>단원명</p>
            </div>
            <div className="score">
              <p>배점</p>
            </div>
            <div className="correct-rate">
              <p>전국정답률</p>
            </div>
          </div>
          {data.scoring.problems.map(
            ({ type, answer, isCorrect, index, nationalCorrectRate, score, curriculumName }) => {
              const CHOICE = type === 'SINGLE_CHOICE' || type === 'MULTIPLE_CHOICE'

              return (
                <div className="row" key={index}>
                  <div
                    className={clsx(
                      'index',
                      data.score === 100 && 'score-100',
                      data.score === 0 && 'score-0',
                      bestIndices.includes(index) && 'good',
                      worstIndices.includes(index) && 'bad',
                    )}
                  >
                    <p>{index}</p>
                  </div>
                  <div className={clsx(CHOICE && 'single-choice', 'answer')}>
                    <p>{CHOICE ? getCircledAnswer(type, answer) : answer}</p>
                  </div>
                  <div className="is-correct">
                    <img
                      src={`${
                        import.meta.env.VITE_AWS_IMAGE_URL
                      }/ability-exam/report/${isCorrect ? 'O' : 'X'}@3x.png`}
                    />
                  </div>
                  <p className="chapter">{curriculumName}</p>
                  <p className="score">{score}</p>
                  <p className="correct-rate">{nationalCorrectRate}%</p>
                </div>
              )
            },
          )}
        </div>
      </S.Judgment>
    </S.Layout>
  )
}

export default 단원별_진단
