const fontSize = {
  heading01: '48px',
  heading02: '32px',
  heading03: '24px',
  heading04: '20px',
  heading05: '18px',
  body01: '16px',
  body02: '14px',
  caption01: '12px',
  caption02: '9px',
}

export default fontSize
