import { css, Global } from '@emotion/react'
import { resetCSS } from './'

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        @media print {
          .no-print {
            display: none;
          }
        }
        font-family: Verdana, Geneva, Tahoma, sans-serif;

        ${resetCSS};

        @font-face {
          font-family: 'Spoqa Han Sans Neo';
          font-weight: 700;
          src:
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/SpoqaHanSansNeo-Bold.woff2`})
              format('woff2'),
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/SpoqaHanSansNeo-Bold.woff`})
              format('woff'),
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/SpoqaHanSansNeo-Bold.otf`})
              format('truetype');
        }

        @font-face {
          font-family: 'Spoqa Han Sans Neo';
          font-weight: 400;
          src:
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/SpoqaHanSansNeo-Regular.woff2`})
              format('woff2'),
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/SpoqaHanSansNeo-Regular.woff`})
              format('woff'),
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/SpoqaHanSansNeo-Regular.otf`})
              format('truetype');
        }

        @font-face {
          font-family: 'Pretendard';
          src: url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/Pretendard-Regular.woff`})
            format('woff');
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/Pretendard-Medium.woff`})
            format('woff');
          font-weight: 500;
          font-style: normal;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/Pretendard-SemiBold.woff`})
            format('woff');
          font-weight: 600;
          font-style: normal;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/Pretendard-Bold.woff`})
            format('woff');
          font-weight: 700;
          font-style: normal;
        }
        @font-face {
          font-family: 'Pretendard';
          src: url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/Pretendard-ExtraBold.woff`})
            format('woff');
          font-weight: 800;
          font-style: normal;
        }

        @font-face {
          font-family: 'TmoneyRoundWind';
          src:
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/TmoneyRoundWindExtraBold.otf`})
              format('truetype'),
            url(${`${import.meta.env.VITE_AWS_S3_DOMAIN}/font/TmoneyRoundWindExtraBold.ttf`})
              format('truetype');
          font-weight: normal;
          font-style: normal;
        }

        * {
          font-family: 'Pretendard' !important;
          box-sizing: border-box;
        }
      `}
    />
  )
}

export default GlobalStyle
