import { css } from '@emotion/react'
import { colors, typo } from 'src/style'

const S = {
  Container: css`
    position: relative;
  `,

  Result: css`
    display: none;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -10;

    &.complete {
      display: block;
    }
  `,
  Wrapper: css`
    display: flex;
    flex-direction: column;

    position: relative;
    width: 100%;
    height: calc(100% - 56px);

    margin: 56px 56px 0px 56px;

    > header {
      display: flex;
      gap: 60px;
      align-items: center;
      flex-direction: column;

      margin-bottom: 40px;

      color: ${colors.gray.$900};
      font-size: 55px;
      font-family: 'NanumMyeongjoExtraBold' !important;
      line-height: 62px;
      letter-spacing: -0.55px;

      > label {
        ${typo.caption01};
        color: ${colors.gray.$600};
        margin-right: auto;
      }
    }

    > main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  `,

  MiddleArticle: css`
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 84px;

    color: ${colors.gray.$900};

    .title {
      font-size: 13px;
      font-weight: 600;
    }
    .academyName,
    .studentName {
      ${typo.heading04};
      font-family: 'NanumMyeongjoExtraBold' !important;
    }
  `,

  ContentArticle: css`
    display: flex;
    gap: 40px;
    flex-direction: column;
    margin-bottom: 65.5px;

    > p {
      text-align: center;
      color: ${colors.gray.$900};
      font-family: 'NanumMyeongjoExtraBold' !important;
      ${typo.heading03};
      line-height: 45px;
    }
    > label {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.03px;
    }
  `,

  Footer: css`
    display: flex;
    justify-content: space-between;

    margin: auto 0px 71px 0px;

    > .left {
      display: flex;
      gap: 6px;
      flex-direction: column;
      justify-content: center;

      color: #de9e6d;
      font-size: 11px;
      line-height: 16.268px;

      > p {
        display: flex;
        gap: 6px;
      }
    }
    > .right {
      display: flex;
      align-items: center;
      gap: 21px;

      img:first-of-type {
        width: 60px;
        height: 60px;
      }

      img:not(:first-of-type) {
        width: 60px;
        height: 16.84px;
      }
    }
  `,

  ExtraBold: css`
    font-weight: 800;
  `,
  SemiBoldFont: css`
    font-weight: 600;
  `,
  MediumFont: css`
    font-weight: 500;
  `,

  AbsoluteMedal: css`
    position: absolute;
    top: 207px;
    right: 47px;

    width: 140px;
    height: 140px;
  `,

  BackgroundRibbon: css`
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    padding: 25px 22px;
  `,
  BackgroundMAATLogo: css`
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 320px;
    height: 249px;
  `,
}

export default S
