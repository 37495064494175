import { registerables } from 'chart.js'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'

import { AbilityExamT } from '../..'

ChartJS.register(
  ...registerables,
  PointElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
)
ChartJS.defaults.font = {
  family: 'Pretendard',
}

type Props = {
  data: AbilityExamT['Report']
  type: CustomLineChartTypes
  index: number
}

type CustomLineChartTypes = 'score' | 'percentile'

const dataByType: Record<CustomLineChartTypes, { title: string; pointUnit: string }> = {
  score: {
    title: '점수 변화',
    pointUnit: '점',
  },
  percentile: {
    title: '백분위 변화',
    pointUnit: '%',
  },
}

const 성적_변화_chart = ({ data, type, index }: Props) => {
  const { pointUnit, title } = dataByType[type]

  return (
    <Line
      style={{ width: 248, height: 144 }}
      width={992}
      height={576}
      plugins={[
        {
          // id는 Global에서 Plugin을 선언하고 Local에서 사용할 때 사용하지만,
          // Local에서 선언 및 사용할 때에는 기입할 필요가 없다. (그러나 required. 대체 왜??)
          id: '',
          beforeInit(chart: any) {
            // Get reference to the original fit function
            const originalFit = chart.legend.fit

            // Override the fit function
            chart.legend.fit = function fit() {
              // Call original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)()
              // Change the height as suggested in another answers
              this.height += 68
            }
          },
        },
      ]}
      options={{
        animation: {
          onComplete() {
            document.querySelector('#result')!.classList.add(`성적_변화${index}`)
          },
        },
        responsive: false,
        scales: {
          x: {
            grid: {
              lineWidth: 4,
              tickColor: '#000',
              tickLength: 20,
              color: (context) => (context.index === 0 ? '#000' : 'transparent'),
            },
            ticks: {
              font: {
                size: 24,
                weight: '700',
                family: 'Pretendard',
              },
              color: '#959595',
            },
          },
          y: {
            max: 100,
            min: 0,
            grid: {
              z: -2,
              lineWidth: 4,
              tickColor: 'transparent',
              tickLength: 16,
              color: (context) => (context.index === 0 ? '#000' : '#e8e8e8'),
            },
            ticks: {
              maxTicksLimit: 6,
              font: {
                size: 24,
                weight: '700',
                family: 'Pretendard',
              },
              color: '#959595',
              callback: (v) => `${v}${pointUnit} `,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: title,
            color: '#5c5c5c',
            font: {
              size: 40,
              weight: '700',
              family: 'Pretendard',
            },
          },
          legend: {
            display: false,
          },
        },
      }}
      data={{
        labels: [
          '',
          ...data.trendOfScoreAndPercentile.map(({ year, month, schoolTypeAndGrade }) => [
            `${year}년`,
            `${month}월(${schoolTypeAndGrade})`,
          ]),
          '',
        ],
        datasets: [
          {
            data: [null, ...data.trendOfScoreAndPercentile.map((data) => data[type]), null],
            backgroundColor: 'rgba(0,171,255, 0.21)',
            fill: true,
            datalabels: {
              color: '#fff',
              font: {
                size: 24,
                weight: 700,
                family: 'Pretendard',
              },
              borderRadius: 8,
              padding: { top: 6, right: 20, bottom: 6, left: 20 },
              backgroundColor: (context) =>
                context.dataIndex === context.dataset.data.length - 2 ? '#00ABFF' : '#959595',
            },
            borderColor: '#00ABFF',
          },
        ],
      }}
    />
  )
}

export default 성적_변화_chart
