import z from 'zod'

const reportModel = z.object({
  name: z.string().min(1),
  score: z.number().gte(0).lte(100),
  type: z.enum(['GRAND_PRIZE', 'GOLD_AWARD', 'SILVER_AWARD', 'BRONZE_AWARD']).nullable(),
  typeStandard: z
    .object({
      type: z.enum(['GRAND_PRIZE', 'GOLD_AWARD', 'SILVER_AWARD', 'BRONZE_AWARD']),
      maxScore: z.number().gte(0).lte(100),
      minScore: z.number().gte(0).lte(100),
    })
    .array(),
  percentile: z.number(),
  academyName: z.string().min(1),
  examTitle: z.string().min(1),
  national: z.object({
    average: z.number().gte(0).lte(100),
    averageOfTop30th: z.number().gte(0).lte(100),
  }),
  trendOfScoreAndPercentile: z
    .object({
      yearMonth: z.string().regex(/\d{4}-(0[1-9]|1[012])/),
      year: z.number().gte(2022),
      month: z.number().gte(1).lte(12),
      schoolTypeAndGrade: z.string().min(2),
      score: z.number().gte(0).lte(100),
      percentile: z.number(),
    })
    .array(),
  correctRateByCurriculum: z
    .object({
      curriculumTitle: z.string().min(1),
      correctRate: z.number().gte(0).lte(100),
      wrongRate: z.number().gte(0).lte(100),
      data: z
        .object({
          curriculumTitle: z.string().min(1),
          countOfProblems: z.number().gte(1),
          correctRate: z.number().gte(0).lte(100),
          nationalCorrectRate: z.number().gte(0).lte(100),
        })
        .array(),
    })
    .array(),
  scoring: z.object({
    curriculums: z.object({
      best: z
        .object({
          indices: z.array(z.number()),
          name: z.string().min(1),
        })
        .array(),
      worst: z
        .object({
          indices: z.array(z.number()),
          name: z.string().min(1),
        })
        .array(),
    }),
    problems: z
      .object({
        index: z.number().gte(1).lte(30),
        type: z.enum(['SINGLE_CHOICE', 'MULTIPLE_CHOICE', 'SHORT_ANSWER']),
        answer: z.string().min(1),
        score: z.number().gte(0).lte(100),
        curriculumName: z.string().min(1),
        isCorrect: z.boolean(),
        nationalCorrectRate: z.number().gte(0).lte(100),
      })
      .array(),
  }),
})

type ReportT = z.infer<typeof reportModel>

export { reportModel }
export type { ReportT }
