import { LoaderFunction } from 'react-router'
import { customEventName, localStorageItem, validator } from 'src/common'

const TIMEOUT_MS = 3000

const defaultLoader: LoaderFunction = async ({ request }) => {
  return await new Promise((resolve, reject) => {
    const getPdfContents = (event: Event) => {
      try {
        const pdfContents = (event as CustomEvent).detail

        localStorage.setItem(localStorageItem.pdfContents, JSON.stringify(pdfContents))

        validator.checkContentByURL(pdfContents, request.url)
        resolve(pdfContents)
      } catch (err) {
        reject(err)
      }
    }

    window.addEventListener(customEventName.getPdfContents, getPdfContents)

    setTimeout(() => {
      localStorage.removeItem(localStorageItem.pdfContents)
      const error = new Error('default loader timeout - no data incoming')
      error.name = 'timeout'

      reject(error)
    }, TIMEOUT_MS)
  })
}

export default defaultLoader
