class Utility {
  convertToCircleNumber(...num: number[]) {
    const arr = ['⓪', '①', '②', '③', '④', '⑤']
    const circled = num.map((n) => {
      if (isNaN(n) || n < 0 || n >= arr.length) {
        return ''
      }

      return arr[n]
    })

    return circled
  }

  s3URL(path: string) {
    return `${import.meta.env.VITE_AWS_IMAGE_URL}/${path}`
  }
}

const utility = new Utility()

export default utility
