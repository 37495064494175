const gray = {
  $50: '#FCFCFC',
  $100: '#FAFAFA',
  $200: '#F5F5F5',
  $230: '#F0F0F0',
  $260: '#EBEBEB',
  $300: '#E8E8E8',
  // border, divider
  $400: '#E0E0E0',
  $470: '#CCCCCC',
  $500: '#C0C0C0',
  $550: '#ABABAB',
  // guide text
  $600: '#959595',
  $700: '#707070',
  $800: '#5c5c5c',
  // main black
  $900: '#333333',
} as const

const blue = {
  $50: '#EEF9FF',
  $100: '#ECF8FF',
  $200: '#94DCFF',
  $300: '#00ABFF',
  $350: '#00A5F5',
  $400: '#1A8EF9',
  $500: '#00A5F5',
} as const

const studentApp = {
  blue: {
    $50: '#E7EAF8',
    $500: '#395FE2',
  },
}

const red = {
  $100: '#FFEBEE',
  $150: '#FFA3AC',
  $200: '#FFCDD2',
  $230: '#FFE0E5',
  $300: '#FF707D',
  $320: '#FF6674',
  $400: '#fd5354',
} as const

const orange = {
  $100: '#F5AA0C',
  $200: '#FA8632',
  $300: '#FF5700',
} as const

const yellow = {
  $100: '#ffc64d',
  $200: '#FFB930',
  $300: '#AE883A',
}
const green = {
  $100: '#54c0b1',
}
// const green = '#54c0b1'
const deepGreen = '#57bf9c'
const positive = '#00C853'
const negative = red.$400
const overlay = 'rgba(0, 0, 0, 0.5)'
const primary = blue.$300
const borderColor = gray.$400
const guide = gray.$700
const rowHoverColor = gray.$100
const textButtonHoverColor = '#8A8A8A'
const inputHoverColor = '#CCCCCC'
const wheat = '#fdf0c8'
const purple = '#6050bd'
const grayHover = gray.$260
const subgreen = '#54c0b1'
const subPurple = '#767DE8'
const subYellow = '#FFB155'

const colors = {
  rowHoverColor,
  textButtonHoverColor,
  inputHoverColor,
  grayHover,
  positive,
  negative,
  overlay,
  primary,
  borderColor,
  guide,
  gray,
  red,
  orange,
  blue,
  yellow,
  green,
  deepGreen,
  wheat,
  purple,
  subPurple,
  subgreen,
  subYellow,
  studentApp,
}

export default colors
