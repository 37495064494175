import z from 'zod'

const academyAwardModel = z.object({
  uuid: z.string().regex(/\d-\d/),
  grade: z.number().gte(1).lte(6),
  schoolType: z.enum(['ELEMENTARY', 'MIDDLE', 'HIGH']),
  academyName: z.string().min(1),
  round: z.number().gte(1),
  date: z.string().regex(/\d{4}-\d{2}-\d{2}/),
})

type AcademyAwardT = z.infer<typeof academyAwardModel>

export { academyAwardModel }
export type { AcademyAwardT }
