import { MAATT } from '../..'
import S from '../Report.style'

type Props = { data: MAATT['ReportT'] }

const RoadMap = ({ data }: Props) => {
  return (
    <article css={S.RoadMap}>
      <header>학습 로드맵</header>
      <div>
        <p>{data.roadMap}</p>
      </div>
    </article>
  )
}

export default RoadMap
