import styled from '@emotion/styled'
import { useRouteError } from 'react-router'
import { localStorageItem, monitoringModule } from 'src/common'
import { PortraitPage } from 'src/component'

const getErrorText = (error) => {
  const errorName = error?.['name']
  if (errorName) {
    if (errorName === 'timeout') {
      return 'data load timeout'
    }

    if (errorName === 'ZodError') {
      return 'data type error'
    }
  } else {
    return 'Unknown Error'
  }
}

const ErrorPage = () => {
  // useRouteError() // Error 값이 필요하다면 주석 풀고 사용

  const error = useRouteError()
  const errorLog = {
    error,
    data: localStorage.getItem(localStorageItem.pdfContents),
  }
  console.error(errorLog)
  monitoringModule.sendError(errorLog)

  return (
    <PortraitPage className="horizontal custom-padding">
      <S.Result id="result" className="failed" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '50px 64px',
          width: '100%',
        }}
      >
        {getErrorText(error)}
      </div>
    </PortraitPage>
  )
}

const S = {
  Result: styled.div`
    display: block;
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -10;
  `,
}

export default ErrorPage
