import { awardModel, AwardPage, AwardT } from './Award'
import { certificationModel, CertificationPage, CertificationT } from './Certification'
import { reportModel, ReportPage, ReportT } from './Report'

const AbilityExam = {
  awardModel,
  certificationModel,
  reportModel,
  AwardPage,
  CertificationPage,
  ReportPage,
}
type AbilityExamT = {
  Award: AwardT
  Certification: CertificationT
  Report: ReportT
}

export { AbilityExam }
export type { AbilityExamT }
