import { css } from '@emotion/react'
import { MAATT } from '../..'
import { colors } from 'src/style'

type Props = {
  data: MAATT['ReportT']
}

const RepAndWeakChapter = ({ data }: Props) => {
  return (
    <div css={S.Wrapper}>
      <section>
        <h6>대표 우수 단원</h6>
        <div>
          {[...data.repBigChapterName, '-', '-', '-'].slice(0, 3).map((bigChapterName) => (
            <div key={bigChapterName} css={S.MiniFont}>
              {' '}
              <p>{bigChapterName}</p>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h6>상대적 취약 단원</h6>
        <div>
          {[...data.weakBigChapterName, '-', '-', '-'].slice(0, 3).map((bigChapterName) => (
            <div key={bigChapterName} css={S.MiniFont}>
              <p>{bigChapterName}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

const S = {
  Wrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    > section {
      flex: 1;

      > h6 {
        color: ${colors.gray.$800};
        font-size: 10px;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 12px;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        border-radius: 4px;
        padding: 8px;
        background: ${colors.gray.$200};
      }
    }
  `,
  MiniFont: css`
    color: ${colors.gray.$900};

    > p {
      font-size: 10px;
      line-height: 180%;
      zoom: 0.8;
    }
  `,
}

export default RepAndWeakChapter
