import styled from '@emotion/styled'

import Page from './Page'

const LandscapePage = styled(Page)`
  width: 842px;
  height: 595px;
  /* width: 29.7cm;
    height: 21cm; */

  @media print {
    @page {
      size: landscape !important;
    }
  }
`

export default LandscapePage
