import z from 'zod'

const certificationModel = z.object({
  name: z.string().min(1),
  type: z.enum(['superior', 'normal']),
  score: z.number().gte(0).lte(100),
  tier: z.enum(['S', 'A', 'B+', 'B', 'B-', 'C']),
  yearMonth: z.string().regex(/\d{4}-(0[1-9]|1[012])/),
  year: z.number(),
  month: z.number().gte(1).lte(12),
  academyName: z.string().min(1),
  examTitle: z.string().min(1),
  uuid: z.string().regex(/\d-\d/),
})

type CertificationT = z.infer<typeof certificationModel>

export { certificationModel }
export type { CertificationT }
