import { useState } from 'react'

import { CONSTANT } from './common'
import { PdfClientRouter, routeName } from './routes'
import { GlobalStyle } from './style'

const App = () => {
  console.log(import.meta.env.VITE_VERCEL_ENV)

  return (
    <>
      {/* puppeteer의 동작도 window['setData']를 거치기에 mock 테스트도 동일한 이벤트 전파 구조를 가짐. */}
      {import.meta.env.VITE_VERCEL_ENV === 'development' && (
        <>
          <PageNavigation />
          <MockDataButtons />
        </>
      )}
      <GlobalStyle />
      <PdfClientRouter />
    </>
  )
}

const MockDataButtons = () => {
  const [awardType, setAwardType] = useState('GRAND_PRIZE')

  return (
    <div className="no-print">
      <span style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <input
            type="radio"
            name="award"
            value="GRAND_PRIZE"
            id="grand-prize"
            checked={awardType === 'GRAND_PRIZE'}
            onChange={(v) => v.target.checked && setAwardType(v.target.value)}
          />
          <label htmlFor="grand-prize">대상</label>
          <input
            type="radio"
            name="award"
            value="GOLD_AWARD"
            id="gold-award"
            checked={awardType === 'GOLD_AWARD'}
            onChange={(v) => v.target.checked && setAwardType(v.target.value)}
          />
          <label htmlFor="gold-award">금상</label>
          <input
            type="radio"
            name="award"
            value="SILVER_AWARD"
            id="silver-award"
            checked={awardType === 'SILVER_AWARD'}
            onChange={(v) => v.target.checked && setAwardType(v.target.value)}
          />
          <label htmlFor="silver-award">은상</label>
          <input
            type="radio"
            name="award"
            value="BRONZE_AWARD"
            id="bronze-award"
            checked={awardType === 'BRONZE_AWARD'}
            onChange={(v) => v.target.checked && setAwardType(v.target.value)}
          />
          <label htmlFor="bronze-award">동상</label>
          <input
            type="radio"
            name="award"
            value="PARTICIPATION_AWARD"
            id="participation-award"
            checked={awardType === 'PARTICIPATION_AWARD'}
            onChange={(v) => v.target.checked && setAwardType(v.target.value)}
          />
          <label htmlFor="participation-award">장려상</label>
        </div>

        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <button
            className="no-print"
            onClick={() =>
              window['setData']({
                ...CONSTANT.abilityExam.award,
                type: awardType,
              })
            }
          >
            중등학력평가 - 상장
          </button>
          <button
            className="no-print"
            onClick={() => window['setData'](CONSTANT.abilityExam.certification_normal)}
          >
            중등학력평가 - 인증서
          </button>
          <button
            className="no-print"
            onClick={() => window['setData'](CONSTANT.abilityExam.certification_superior)}
          >
            중등학력평가 - 우수 인증서
          </button>
          <button
            className="no-print"
            onClick={() => window['setData'](CONSTANT.abilityExam.report)}
          >
            중등학력평가 - 결과지
          </button>
        </div>

        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <button
            className="no-print"
            onClick={() => {
              window['setData']({ ...CONSTANT.MAAT.award, tier: awardType })
            }}
          >
            MAAT - 상장
          </button>
          <button
            className="no-print"
            onClick={() => {
              window['setData'](CONSTANT.MAAT.academyAward)
            }}
          >
            MAAT - 최우수 기관상
          </button>
          <button
            className="no-print"
            onClick={() => {
              window['setData'](CONSTANT.MAAT.report)
            }}
          >
            MAAT - 결과지
          </button>
          <button
            className="no-print"
            onClick={() => {
              window['setData'](CONSTANT.MAAT.report_0점)
            }}
          >
            MAAT - 결과지 0점
          </button>
        </div>
      </span>
    </div>
  )
}

const PageNavigation = () => {
  return (
    <div className="no-print">
      <b>경로 이동</b>
      {Object.entries(routeName).map(([name, url]) => (
        <a href={url} key={name} style={{ marginLeft: 8 }}>
          {name}
        </a>
      ))}
    </div>
  )
}

export default App
